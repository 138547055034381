import { createTheme, ThemeProvider } from "@mui/material"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./index.css"

import store from "./redux/store"
import reportWebVitals from "./reportWebVitals"
import { isDevelopment, isLocal } from "./utils"

const theme = createTheme({
    breakpoints: {
        values: {
            tablet: 860,
            iphonePRO: 600,
            desktop: 1200
        },
    },
});

Sentry.init({
    dsn: "https://133a0d3c970c443a8852c148ab59ca3e@cross-sentry.belka-games.com/14",
    integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.GlobalHandlers({
            onerror: true,
            onunhandledrejection: true
        })
    ],
    tracesSampleRate: 1.0,
    enabled: !isDevelopment() && !isLocal()
})

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
              <ThemeProvider theme={theme}><App/></ThemeProvider>
          </BrowserRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
