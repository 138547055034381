import { Box, Button, Card, Chip, Grid, Link, MenuItem, Stack, TextField, Checkbox } from "@mui/material"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import WarningDialog from "./WarningDialog"
import manageRequest, {SelectProps} from "./hoc/manageRequest";
import React, { useEffect, useState } from "react"
import {CURRENCIES} from "./hoc/manageRequest";
import {SPORT_REQUEST} from "../requestTypes";
import {chooseInitRequest, getOrDefault, isExists} from "../../utils";
import RequestBar from "./RequestBar";
import RequestInputStyle from "./MaterialStyles";
import {MobileDatePicker} from "@mui/lab";
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink"

const REQUIRED_FIELDS = ["fullName", "price", "startDate", "duration", "currency", "images"]

function SportCompensationRequest(props) {
    const MONTHS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];


    useEffect(() => {
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        let initRequest = {
            fullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            office: props.findOfficeIfExist(props.userProfile.location.name.trim()),
            price: "",
            currency: props.findCurrencyByLocation(props.userProfile.location.name.trim()),
            startDate: "",
            duration: "",
            images: [],
            comment: ""
        }
        props.setInitRequest(()=>()=>props.setRequest(initRequest));
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)


    }, [])

    return <>
        <RequestBar header={<><b>Система заявок:</b> компенсация спорта</>} />
        <Box className="select_request">
            <Card>
            <Box component="form">
                <Box sx={{px: "5%"}}>
                    <RequestDescription>
                        В здоровом теле — здоровый дух!
                        Мы рады поддержать коллег в стремлении держать себя в форме,
                        поэтому компенсируем 50% от затрат на занятия любым спортом.
                        Чтобы мы смогли тебе помочь, заполни форму ниже.
                        Подробная информация о компенсации спорта находится
                        <RequestDescriptionLink page="BC/pages/360022114" spaceBefore>
                            здесь
                        </RequestDescriptionLink>
                        .
                    </RequestDescription>
                    <RequestInputStyle>
                        <TextField
                            required
                            error={props.errorForm ? props.isEmptyInput("price") : false}
                            label="Стоимость абонемента"
                            name="price"
                            sx={{mb: 3, mr: "3%"}}
                            className="request__input"
                            type="number"
                            value={props.request.price ? props.request.price : ""}
                            onChange={props.handleChange}
                            helperText={(props.errorForm && props.isEmptyInput("price")) ? "Поле не должно быть пустым." : ""}
                        />
                        <TextField
                            required
                            select
                            SelectProps={SelectProps}
                            label="Валюта"
                            value={props.request.currency ? props.request.currency : ""}
                            onChange={props.handleChange}
                            name="currency"
                            className="request__input"
                            sx={{mb: 3}}
                            error={props.errorForm ? props.isEmptyInput("currency") : false}
                            helperText={(props.errorForm && props.isEmptyInput("currency")) ? "Поле не должно быть пустым." : ""}
                        >
                            {CURRENCIES.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Начало абонемента"
                                name="startDate"
                                showToolbar={false}
                                disableFuture={true}
                                value={props.request.startDate ? props.request.startDate : ""}
                                onChange={(date) => props.handleChangeDate(date, "startDate")}
                                renderInput={(params) => <TextField {...params} name="startDate"
                                                                    required
                                                                    error={props.errorForm ? props.isEmptyInput("startDate") : false}
                                                                    helperText={(props.errorForm && props.isEmptyInput("startDate")) ? "Поле не должно быть пустым." : ""}
                                                                    className="request__input"
                                                                    sx={{mr: "3%", mb: 3}}/>}
                            />
                        </LocalizationProvider>
                        <TextField
                            required
                            select
                            label="Длительность абонемента (мес.)"
                            name="duration"
                            value={props.request.duration ? props.request.duration : ""}
                            onChange={props.handleChange}
                            className="request__input"
                            SelectProps={SelectProps}
                            sx={{mb: 3}}
                            error={props.errorForm ? props.isEmptyInput("duration") : false}
                            helperText={(props.errorForm && props.isEmptyInput("duration")) ? "Поле не должно быть пустым." : ""}
                        >
                            {MONTHS.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </RequestInputStyle>
                    <Box sx={{py: 2}}>
                        <Box sx={{textAlign: "center", width: "300px", display: "block", margin: "0 auto"}}><label htmlFor="upload-photo">
                            <input
                                style={{display: "none"}}
                                id="upload-photo"
                                accept="image/*,application/pdf"
                                name="images"
                                type="file"
                                multiple
                                onChange={props.handleChangeFiles}
                            />
                            <Box>
                                <Button
                                    fullWidth
                                    component="span"
                                    variant="contained"
                                >
                                    Загрузить фото/скан чека*
                                </Button>
                                {(props.errorForm && props.isEmptyInput("images")) &&
                                <p className="file_loading_helper_text">
                                    Необходимо загрузить фото/скан
                                </p>}
                            </Box>
                        </label>
                        </Box>
                        <Grid container justifyContent="center">
                            <p className="file_loading_helper_text color_black">
                                <b>Общий размер файлов не должен превышать 30MB</b>
                            </p>
                        </Grid>
                        {props.request.images !== undefined &&
                        <Stack direction="column" spacing={1} sx={{textAlign: "center", alignItems: "center", marginTop: 2}}>
                            {props.request.images.map((image, index) =>
                                <Chip key={index} sx={{width: "50%", justifyContent: "space-between"}}
                                      label={image.name}
                                      onDelete={() => props.handleDeleteImage(index)}/>
                            )}
                        </Stack>
                        }

                    </Box>
                    <Box sx={{textAlign: "left", my: 3, background: "white"}}>
                        <Box sx={{fontWeight: "bold"}}>Если нет
                        возможности
                        предоставить чек,</Box>
                        скачайте форму по <Link href="https://bit.ly/3F6LvDi" underline="hover" rel="noreferrer"
                                                target="_blank">ссылке</Link>.<br/>
                        Попросите тренера заполнить квитанцию на оплату, которую вы произвели.
                        Распечатайте и приложите скан с подписями</Box>
                    <TextField
                        label="Комментарий"
                        name="comment"
                        value={props.request.comment ? props.request.comment : ""}
                        fullWidth
                        multiline
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                    />

                </Box>
                {props.children}
            </Box>
            </Card>
        </Box>

    </>
}

export default manageRequest(SportCompensationRequest, SPORT_REQUEST)
