import {Box, Card, MenuItem, TextField, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import {chooseInitRequest, isExists} from "../../utils"
import {BOOK_REQUEST, OFFICE_APPLIANCES_REQUEST} from "../requestTypes"
import manageRequest, {SelectProps} from "./hoc/manageRequest"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"

const REQUIRED_FIELDS = ["employeeFullName", "location", "appliancesList"]

const OfficeAppliancesRequest = (props) => {
    const [requestUnavailable, setRequestUnavailable] = useState(false)

    useEffect(() => {
        const initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            location: props.findOfficeIfExist(props.userProfile.location.name),
            appliancesList: "",
            comment: ""
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)

        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)
        setRequestUnavailable(
            isExists(props.findOfficeIfExist(props.userProfile.location.name))
                ? !(props.findOfficeIfExist(props.userProfile.location.name) === "Кипр" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Литва" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Грузия" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Украина" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "РБ" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Черногория")
                : false
        )

    }, [])

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> заказать книгу</>}/>
            <Box className="select_request">
                {!requestUnavailable ?
                <Card>
                    <Box component="form">
                        <Box sx={{px: "5%"}}>
                            <RequestDescription>
                                Форма для получения канцелярии в офис
                                {/*<RequestDescriptionLink page="BC/pages/749633682/Belka+Games" spaceBefore>*/}
                                {/*    здесь.*/}
                                {/*</RequestDescriptionLink>*/}
                            </RequestDescription>
                            <TextField
                                required
                                fullWidth
                                sx={{mb: 3, background: "white"}}
                                label="Что хочешь заказать в офис"
                                name="appliancesList"
                                value={props.request.appliancesList ? props.request.appliancesList : ""}
                                helperText={(props.errorForm && props.isEmptyInput("appliancesList"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("appliancesList") : false}
                                onChange={props.handleChange}
                            />

                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                sx={{mb: 3, background: "white"}}
                                label="Комментарий"
                                name="comment"
                                value={props.request.comment ? props.request.comment : ""}
                                onChange={props.handleChange}
                            />
                        </Box>
                        {props.children}
                    </Box>
                </Card> :
                <Card sx={{m: 4}}>
                    <Typography variant={"h6"} sx={{m: 4}}>
                        Привет! К сожалению, в твоей локации нет офиса Belka Games, поэтому заявку на заказ канцелярии оставить невозможно.
                    </Typography>
                </Card>
                }
            </Box>
        </>
    )
}

export default manageRequest(OfficeAppliancesRequest, OFFICE_APPLIANCES_REQUEST)
