import React, {useEffect} from "react";
import RequestBar from "./RequestBar";
import {Box, Button, Card, Chip, Grid, MenuItem, Stack, TextField, Typography} from "@mui/material";
import RequestDescription from "./RequestDescription";
import manageRequest, {SelectProps} from "./hoc/manageRequest";
import {chooseInitRequest, isExists} from "../../utils";
import {BELKA_BUGS_REQUEST} from "../requestTypes";
import RequestDescriptionLink from "./RequestDescriptionLink";

const REQUIRED_FIELDS = [
    "employeeFullName",
    "project",
    "description",
    "images",
    "device",
    "OSVersion"
]
const BelkaBugsRequest = (props) => {
    useEffect(() => {
        const initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            project: "",
            description: "",
            playbackSteps: "",
            images: [],
            device: "",
            OSVersion: ""
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)

        props.setRequest(chooseInitRequest(sessionRequest, initRequest));

        props.setRequiredFields(REQUIRED_FIELDS)

    }, [])

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> Belka bugs</>}/>
            <Box className="select_request">
                <Card>
                    <Box component="form">
                        <Box sx={{px: "5%"}}>
                            <RequestDescription>
                                Привет, здесь можно оставить заявку на исправление багов в играх Belka Games
                                Ознакомиться с правилами оформления можно
                                <RequestDescriptionLink page="QA/pages/3643932944" spaceBefore>
                                    здесь
                                </RequestDescriptionLink>
                            </RequestDescription>
                            <TextField
                                style={{
                                    textAlign: "left"
                                }}
                                required
                                select
                                fullWidth
                                sx={{mb: 3}}
                                label="Укажи проект, на котором нашелся баг"
                                SelectProps={SelectProps}
                                className="request__input"
                                name="project"
                                value={props.request.project ? props.request.project : ""}
                                helperText={(props.errorForm && props.isEmptyInput("project"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("project") : false}
                                onChange={props.handleChange}
                            >

                                <MenuItem value="CM">
                                    Clockmaker
                                </MenuItem>
                                <MenuItem value="COMMUNITY">
                                    Community Portal
                                </MenuItem>
                                <MenuItem value="TOH">
                                    Train of Hope
                                </MenuItem>
                            </TextField>
                            <Typography
                                sx={{mb: 1}}
                                variant="body2">
                                Вкратце опиши найденный дефект. Желательно, чтобы описание отвечало на 3 главных вопроса: Что? Где? Когда?
                            </Typography>
                            <TextField
                                required
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 120 }}
                                max
                                rows={2}
                                sx={{ mb: 3, background: "white",   whiteSpace: 'normal', textOverflow: 'ellipsis' }}
                                label="Краткое описание"
                                name="description"
                                value={props.request.description ? props.request.description : ""}
                                helperText={(props.errorForm && props.isEmptyInput("description"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("description") : false}
                                onChange={props.handleChange}
                            />
                            <Typography
                                sx={{mb: 1}}
                                variant="body2">
                                Опиши в произвольной форме шаги, по которым у тебя получилось воспроизвести дефект. Тут можно указать любую дополнительную информацию
                            </Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                sx={{ mb: 3, background: "white", overflow: "" }}
                                label="Шаги воспроизведения"
                                name="playbackSteps"
                                value={props.request.playbackSteps ? props.request.playbackSteps : ""}
                                onChange={props.handleChange}
                            />
                            <Typography
                                sx={{mb: 1}}
                                variant="body2">
                                Прикрепи скрин или видео подтверждающее наличие дефекта. Большая просьба указывать стрелкой проблемное место на скрине, если прикрепляешь видео - укажи в описании время с моментом бага. Если видео не загружается, пожалуйста загрузи его на GDrive и оставь ссылку в описании
                            </Typography>
                            <Box sx={{ py: 2 }}>
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        width: "300px",
                                        display: "block",
                                        margin: "0 auto"
                                    }}
                                ><label htmlFor="upload-photo">
                                    <input
                                        style={{ display: "none" }}
                                        id="upload-photo"
                                        type="file"
                                        accept="image/*,application/pdf"
                                        multiple
                                        name="images"
                                        onChange={props.handleChangeFiles}
                                    />
                                    <Box>
                                        <Button
                                            fullWidth
                                            component="span"
                                            variant="contained"
                                        >
                                            Загрузить скриншот или видео*
                                        </Button>
                                        {(props.errorForm && props.isEmptyInput("images")) &&
                                            <p className="file_loading_helper_text">
                                                Необходимо загрузить скриншот или видео
                                            </p>}
                                    </Box>
                                </label>
                                </Box>
                                <Grid
                                    container
                                    justifyContent="center"
                                >
                                    <p className="file_loading_helper_text color_black">
                                        <b>*Общий размер файлов не должен превышать 30MB</b><br />
                                    </p>
                                </Grid>
                                {isExists(props.request.images) &&
                                    <Stack
                                        sx={{ textAlign: "center", alignItems: "center", marginTop: 2 }}
                                        direction="column"
                                        spacing={1}
                                    >
                                        {props.request.images.map((image, index) =>
                                            <Chip
                                                sx={{ width: "50%", justifyContent: "space-between" }}
                                                key={index}
                                                label={image.name}
                                                onDelete={() => props.handleDeleteImage(index)}
                                            />
                                        )}
                                    </Stack>
                                }
                            </Box>

                            <TextField
                                required
                                fullWidth
                                multiline
                                rows={1}
                                sx={{ mb: 1, background: "white" }}
                                label="Модель твоего устройства"
                                name="device"
                                value={props.request.device ? props.request.device : ""}
                                helperText={(props.errorForm && props.isEmptyInput("device"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("device") : false}
                                onChange={props.handleChange}
                            />
                            <TextField
                                required
                                fullWidth
                                multiline
                                rows={1}
                                sx={{ mb: 1, background: "white" }}
                                label="Версия ОС"
                                name="OSVersion"
                                value={props.request.OSVersion ? props.request.OSVersion : ""}
                                helperText={(props.errorForm && props.isEmptyInput("OSVersion"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("OSVersion") : false}
                                onChange={props.handleChange}
                            />
                            <Typography variant="body2">
                                Внимание! Баги на OS Android 7 и ниже, а так же iOS 12 и ниже приниматься не будут
                            </Typography>
                        </Box>
                        {props.children}
                    </Box>
                </Card>
            </Box>
        </>
)
}

export default manageRequest(BelkaBugsRequest, BELKA_BUGS_REQUEST)