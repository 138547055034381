import {Box, Card, MenuItem, TextField, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import {chooseInitRequest, getOrDefault, isExists, userIsLead} from "../../utils"
import {REVISION_SALARY_REQUEST} from "../requestTypes"
import manageRequest, {SALARY_CURRENCIES, SelectProps} from "./hoc/manageRequest"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink";
import RequestInputStyle from "./MaterialStyles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {MobileDatePicker} from "@mui/lab";
import {format, isValid} from "date-fns";

const REQUIRED_FIELDS = ["currentSalary", "currency", "lastRevisionDate"]

const SalaryRevisionRequest = (props) => {
    const [requestUnavailable, setRequestUnavailable] = useState(false)

    useEffect(() => {
        const initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            location: props.findOfficeIfExist(props.userProfile.location.name.trim()),
            position: props.findPositionIfExist(props.userProfile.position.name.trim()),
            currentSalary: 0,
            currency: props.findCurrencyByLocation(props.userProfile.location.name.trim()),
            lastRevisionDate: "",
            comment: ""
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)

        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)
        setRequestUnavailable(true/*!userIsLead(props.userProfile)*/)

    }, [])

    const handleChangeEndDate = (date) => {
        if (isValid(date)) {
            props.setRequest({
                ...props.request,
                lastRevisionDate: format(date, "yyyy-MM-dd")
            })
        }
    }

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> пересмотр зарплаты</>}/>
            <Box className="select_request">
                {!requestUnavailable ?
                    <Card>
                        <Box component="form">
                            <Box sx={{px: "5%"}}>
                                <RequestDescription>
                                    Инструмент для лидов, помогающий понять насколько предложение о пересмотре зарплат
                                    соответствует требованиям политики, которая размещена
                                    <RequestDescriptionLink page="TL/pages/2675867707/TL" spaceBefore>
                                        здесь.
                                    </RequestDescriptionLink>
                                </RequestDescription>
                                <RequestInputStyle>
                                    <TextField
                                        required
                                        type="number"
                                        sx={{mb: 3, mr: "4%"}}
                                        label="Введите размер заработной платы"
                                        name="currentSalary"
                                        className="request__input"
                                        disabled={requestUnavailable}
                                        value={props.request.currentSalary ? props.request.currentSalary : ""}
                                        helperText={(props.errorForm && props.isEmptyInput("currentSalary"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm ? props.isEmptyInput("currentSalary") : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        required
                                        select
                                        sx={{mb: 3}}
                                        label="Выберите валюту"
                                        SelectProps={SelectProps}
                                        className="request__input"
                                        name="currency"
                                        disabled={requestUnavailable}
                                        value={props.request.currency ? props.request.currency : ""}
                                        helperText={(props.errorForm && props.isEmptyInput("currency"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm ? props.isEmptyInput("currency") : false}
                                        onChange={props.handleChange}
                                    >
                                        {SALARY_CURRENCIES.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </RequestInputStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        showToolbar={false}
                                        label="Дата последнего пересмотра зарплаты"
                                        name="lastRevisionDate"
                                        value={getOrDefault(props.request.lastRevisionDate)}
                                        onChange={handleChangeEndDate}
                                        disableFuture={true}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                sx={{ width: "100%", mb: 3, background: "white" }}
                                                required
                                                helperText={(props.errorForm && props.isEmptyInput(
                                                    "lastRevisionDate"))
                                                    ? "Поле не должно быть пустым."
                                                    : ""}
                                                error={props.errorForm
                                                    ? props.isEmptyInput("lastRevisionDate")
                                                    : false}
                                            />}
                                    />
                                </LocalizationProvider>

                            </Box>
                            {props.children}
                        </Box>
                    </Card> :
                    <Card sx={{m: 4}}>
                        <Typography variant={"h6"} sx={{m: 4}}>
                            Этот иструмент не доступен
                        </Typography>
                    </Card>
                }
            </Box>
        </>
    )
}

export default manageRequest(SalaryRevisionRequest, REVISION_SALARY_REQUEST)
