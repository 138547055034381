import {AUTH_USER, REQUEST_TYPE} from "./types";

export const setRequestType =(requestType)=> {
    return async  dispatch => {
        dispatch({type: REQUEST_TYPE,
            payload:requestType})
    }
}
export const setUser =(user)=> {
    return dispatch => {
        dispatch({type: AUTH_USER,
            payload:user})
    }
}

export const logoutUser =()=> {
    return dispatch => {
        dispatch({type: AUTH_USER,
            payload:null})
    }
}