import {Box, DialogContentText, Typography} from "@mui/material";
import {DataGrid, useGridApiRef} from '@mui/x-data-grid';
import {useEffect, useRef, useState} from "react";


const columns = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true
},
    {
        field: 'location',
        headerName: 'Location',
        width: 100,
        sortable: false,
        renderCell: (params) => {
            return <strong>{params.row.location}</strong>;
        }
    },
    {
        field: 'totalCost',
        headerName: 'Total cost, $',
        width: 130,
        sortable: false,
        renderCell: (params) => {
            return <strong>{params.row.totalCost}</strong>;
        }
    },
    {
        field: 'payrolls',
        headerName: 'Payroll, $',
        width: 130,
        sortable: false
    },
    {
        field: 'taxes',
        headerName: 'Payroll taxes, $',
        sortable: false,
        width: 130,
    },
    {
        field: 'overHeads',
        headerName: 'Overheads, $',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 130,
    },

];

export default function WorkplaceResponse(props) {
    const apiRef = useGridApiRef();

    const findLocationIndex = () => {
        let locationIndex = 0;
        console.log(props.result.locationCosts)
        props.result.locationCosts.map((reult, index) => {
            if (reult.location.includes(props.request.location)) {
                locationIndex = index;
            }
        })
        return locationIndex;
    }

    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setColumnVisibility('id', false); // Hide 'col2'
            apiRef.current.selectRow(findLocationIndex(), true); // Select row with id 1 by default
        }
    }, []);



    return (
        props.result &&
        <DialogContentText
            id="alert-dialog-description"
            sx={{textAlign: "center", pt: 3}}
        >
            {props.result &&
                <Box sx={{ height: 470, width: '100%' }}>
                    <DataGrid
                        apiRef={apiRef}

                        rows={props.result.locationCosts}
                        columns={columns}
                        hideFooter
                        disableColumnFilter
                        disableColumnMenu

                    />

                    <Typography sx={{float: 'left'}}>
                        <i>* суммы указаны из расчета за 12 месяцев</i>
                    </Typography>
                </Box>}
        </DialogContentText>
    );
}