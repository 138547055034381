import manageRequest, {CURRENCIES, SelectProps} from "./hoc/manageRequest";
import {Box, Card, Grid, Link, MenuItem, TextField} from "@mui/material"
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React, {useEffect, useState} from "react"
import {EDUCATION_REQUEST} from "../requestTypes";
import {format, isValid} from "date-fns";
import {chooseInitRequest, getDiffDateByMonth, isExists} from "../../utils"
import RequestBar from "./RequestBar";
import RequestInputStyle from "./MaterialStyles";
import {MobileDatePicker} from "@mui/lab";
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink"
import WarningDialog from "./WarningDialog"
import moment from 'moment'

const REQUIRED_FIELDS = ["fullName", "managerFullName", "courseLink", "price", "educationEndDate", "currency", "provider",
"courseSpecializationArea", "courseName", "purchaseDate"]

const COURSE_SPEC_OPTIONS = ["Art", "Game-design", "Tech", "Analytics", "Management", "Marketing", "Other"]

function EducationCompensationRequest(props) {
    const [requestUnavailable, setRequestUnavailable] = useState(false)

    function getManagerFullName() {
        if (isExists(props.userProfile.reporting_to)) {
            const manager = props.userProfile.reporting_to
            if (isExists(manager.first_name) && isExists(manager.last_name)) {
                return `${manager.first_name} ${manager.last_name}`
            }
        }
        return ""
    }

    useEffect(() => {
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        let initRequest = {
            fullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            managerFullName: getManagerFullName(),
            office: props.findOfficeIfExist(props.userProfile.location.name.trim()),
            courseLink: "",
            provider: "",
            courseName: "",
            courseSpecializationArea: "",
            price: "",
            currency: props.findCurrencyByLocation(props.userProfile.location.name.trim()),
            educationEndDate: "",
            purchaseDate: "",
            comment: ""
        }
        props.setInitRequest(() => () => props.setRequest(initRequest));
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)
        setRequestUnavailable(
            isExists(props.userProfile.hired_on)
                ? getDiffDateByMonth(moment(), new Date(props.userProfile.hired_on)) <= 6
                : false
        )    }, [])
    function handleChangeEndDate(data) {
        if (isValid(data)) {
            props.setRequest({
                ...props.request,
                educationEndDate: format(data, "yyyy-MM-dd")
            })
        }
    }

    return <>
        <RequestBar header={<><b>Система заявок:</b> компенсация обучения</>}/>
        <Box className="select_request">
            <Card>
            <Box component="form" noValidate>
                <Box sx={{px: "5%"}}>
                    <RequestDescription>
                        Как принято говорить — ученье свет!
                        Мы с этим абсолютно согласны, поэтому компенсируем 50% расходов на обучение.
                        Для этого заполни, пожалуйста, заявку ниже.
                        Все детали компенсации мы описали
                        <RequestDescriptionLink page="BC/pages/359596240" spaceBefore>
                            здесь
                        </RequestDescriptionLink>
                        .
                    </RequestDescription>
                    <TextField
                        required
                        error={props.errorForm ? props.isEmptyInput("courseLink") : false}
                        label="Ссылка на курс"
                        name="courseLink"
                        disabled={requestUnavailable}
                        value={props.request.courseLink ? props.request.courseLink : ""}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                        helperText={(props.errorForm && props.isEmptyInput("courseLink")) ? "Поле не должно быть пустым." : ""}

                    />
                    <RequestInputStyle>
                        <TextField
                            required
                            error={props.errorForm ? props.isEmptyInput("provider") : false}
                            label="Название школы"
                            name="provider"
                            disabled={requestUnavailable}
                            value={props.request.provider ? props.request.provider : ""}
                            className="request__input"
                            sx={{mb: 3, background: "white", width: '30%', mr: "5%"}}
                            onChange={props.handleChange}
                            helperText={(props.errorForm && props.isEmptyInput("provider")) ? "Поле не должно быть пустым." : ""}

                        />
                        <TextField
                            required
                            error={props.errorForm ? props.isEmptyInput("courseName") : false}
                            label="Название курса"
                            name="courseName"
                            disabled={requestUnavailable}
                            value={props.request.courseName ? props.request.courseName : ""}
                            sx={{mb: 3, background: "white", width: '30%', mr: "5%"}}
                            onChange={props.handleChange}
                            helperText={(props.errorForm && props.isEmptyInput("courseName")) ? "Поле не должно быть пустым." : ""}

                        />
                        <TextField
                            select
                            required
                            error={props.errorForm ? props.isEmptyInput("courseSpecializationArea") : false}
                            label="Специализация курса"
                            name="courseSpecializationArea"
                            disabled={requestUnavailable}
                            value={props.request.courseSpecializationArea ? props.request.courseSpecializationArea : ""}
                            sx={{mb: 3, background: "white", width: '30%'}}
                            onChange={props.handleChange}
                            helperText={(props.errorForm && props.isEmptyInput("courseSpecializationArea")) ? "Поле не должно быть пустым." : ""}
                        >
                            {COURSE_SPEC_OPTIONS.map(option =>
                                <MenuItem key={option} value={option}>{option}</MenuItem>)}

                        </TextField>
                    </RequestInputStyle>

                    <RequestInputStyle>
                        <TextField
                            required
                            error={props.errorForm ? props.isEmptyInput("price") : false}
                            label="Стоимость обучения, Формат: 'xxx.xx'"
                            name="price"
                            sx={{mb: 3, mr: "4%"}}
                            className="request__input"
                            type="number"
                            disabled={requestUnavailable}
                            value={props.request.price ? props.request.price : ""}
                            onChange={props.handleChange}
                            helperText={(props.errorForm && props.isEmptyInput("price")) ? "Поле не должно быть пустым." : ""}

                        />
                        <TextField
                            required
                            select
                            SelectProps={SelectProps}
                            label="Валюта"
                            disabled={requestUnavailable}
                            value={props.request.currency ? props.request.currency : ""}
                            onChange={props.handleChange}
                            name="currency"
                            className="request__input"
                            sx={{mb: 3}}
                            error={props.errorForm ? props.isEmptyInput("currency") : false}
                            helperText={(props.errorForm && props.isEmptyInput("currency")) ? "Поле не должно быть пустым." : ""}
                        >
                            {CURRENCIES.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </RequestInputStyle>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            showToolbar={false}
                            label="Дата завершения обучения"
                            name="educationEndDate"
                            disabled={requestUnavailable}
                            value={props.request.educationEndDate ? props.request.educationEndDate : ""}
                            onChange={handleChangeEndDate}
                            renderInput={(params) => <TextField {...params}
                                                                required
                                                                error={props.errorForm ? props.isEmptyInput("educationEndDate") : false}
                                                                helperText={(props.errorForm && props.isEmptyInput("educationEndDate")) ? "Поле не должно быть пустым." : ""}
                                                                sx={{
                                                                    width: "100%",
                                                                    mb: 3,
                                                                    background: "white",
                                                                    color: "black"
                                                                }}/>}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputFormat="dd.MM.yyyy"
                            label="Дата оплаты"
                            name="paymentDate"
                            type="date"
                            disableFuture={true}
                            showToolbar={false}
                            value={props.request.purchaseDate ? props.request.purchaseDate : ""}
                            onChange={(date) => props.handleChangeDate(date, "purchaseDate")}
                            renderInput={(params) => <TextField {...params} name="birthDate"
                                                                required
                                                                error={props.errorForm ? props.isEmptyInput("purchaseDate") : false}
                                                                helperText={(props.errorForm && props.isEmptyInput("purchaseDate")) ? "Поле не должно быть пустым." : ""}
                                                                className="request__input"
                                                                sx={{mr: "3%", mb: 3, width:220}}/>}
                        />
                    </LocalizationProvider>
                    <TextField
                        label="Комментарий"
                        name="comment"
                        disabled={requestUnavailable}
                        value={props.request.comment ? props.request.comment : ""}
                        fullWidth
                        multiline
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                    />
                </Box>
                {props.children}
            </Box>
            </Card>
        </Box>
        <WarningDialog open={requestUnavailable} onClose={() => setRequestUnavailable(false)}>
            Ты проработал в компании меньше 6 месяцев.<br/>
            Отправить заявку невозможно.<br/>
            <Link
                href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/359596240"
                rel="noopener noreferrer"
                target="_blank"
            >
                Прочитать подробнее: Confluence
            </Link>
        </WarningDialog>
    </>
}

export default manageRequest(EducationCompensationRequest, EDUCATION_REQUEST)
