import { Box, Card, MenuItem, TextField } from "@mui/material"
import React, { useEffect } from "react"
import {chooseInitRequest} from "../../utils"
import { DEVICE_REQUEST } from "../requestTypes"
import manageRequest, { LOCATIONS_RU, SelectProps } from "./hoc/manageRequest"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"

const REQUIRED_FIELDS = ["employeeFullName", "location", "priority", "deviceName", "comment"]

const DeviceRequest = (props) => {
    useEffect(() => {

        const fullname = props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "";

        const initRequest = {
            employeeFullName: fullname,
            location: "",
            priority: "",
            deviceName: "",
            comment: ""
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)

        let request = chooseInitRequest(sessionRequest, initRequest)
        request.fullname = fullname
        props.setRequest(request);

        props.setRequiredFields(REQUIRED_FIELDS)
    }, [])

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> заказ ПО или оборудования</>} />
            <Box className="select_request">
                <Card>
                <Box component="form">
                    <Box sx={{ px: "5%" }}>
                        <RequestDescription>
                            Привет и добро пожаловать в форму, которая исполняет желания!
                            Здесь ты можешь заказать любое оборудование или программное обеспечение, необходимое для работы.
                            Для этого нужно лишь заполнить заявку ниже:
                        </RequestDescription>
                        <TextField
                            style={{
                                textAlign: "left"
                            }}
                            required
                            select
                            fullWidth
                            sx={{ mb: 3 }}
                            label="Локация"
                            SelectProps={SelectProps}
                            className="request__input"
                            name="location"
                            value={props.request.location ? props.request.location : ""}
                            helperText={(props.errorForm && props.isEmptyInput("location"))
                                ? "Поле не должно быть пустым."
                                : ""}
                            error={props.errorForm ? props.isEmptyInput("location") : false}
                            onChange={props.handleChange}
                        >
                            {LOCATIONS_RU.map((location) => (
                                <MenuItem
                                    key={location}
                                    value={location}
                                >
                                    {location}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            style={{
                                textAlign: "left"
                            }}
                            required
                            select
                            fullWidth
                            sx={{ mb: 3 }}
                            label="Приоритет"
                            SelectProps={SelectProps}
                            className="request__input"
                            name="priority"
                            value={props.request.priority ? props.request.priority : ""}
                            helperText={(props.errorForm && props.isEmptyInput("priority"))
                                ? "Поле не должно быть пустым."
                                : ""}
                            error={props.errorForm ? props.isEmptyInput("priority") : false}
                            onChange={props.handleChange}
                        >
                            <MenuItem value="LOW">
                                Низкий
                            </MenuItem>
                            <MenuItem value="MEDIUM">
                                Средний
                            </MenuItem>
                            <MenuItem value="HIGH">
                                Высокий
                            </MenuItem>
                        </TextField>
                        <TextField
                            required
                            fullWidth
                            sx={{ mb: 3, background: "white" }}
                            label="Необходимое оборудование или название ПО"
                            name="deviceName"
                            value={props.request.deviceName ? props.request.deviceName : ""}
                            helperText={(props.errorForm && props.isEmptyInput("deviceName"))
                                ? "Поле не должно быть пустым."
                                : ""}
                            error={props.errorForm ? props.isEmptyInput("deviceName") : false}
                            onChange={props.handleChange}
                        />
                        <TextField
                            required
                            fullWidth
                            multiline
                            rows={3}
                            sx={{ mb: 3, background: "white" }}
                            label="Комментарий"
                            name="comment"
                            value={props.request.comment ? props.request.comment : ""}
                            helperText={(props.errorForm && props.isEmptyInput("comment"))
                                ? "Поле не должно быть пустым."
                                : ""}
                            error={props.errorForm ? props.isEmptyInput("comment") : false}
                            onChange={props.handleChange}
                        />
                    </Box>
                    {props.children}
                </Box>
                </Card>
            </Box>
        </>
    )
}

export default manageRequest(DeviceRequest, DEVICE_REQUEST)
