export const CONFERENCE_REQUEST = "conference"
export const DEVICE_REQUEST = "device"
export const EDUCATION_REQUEST = "education"
export const ENGLISH_LANGUAGE = "english"
export const RECOMMENDATION_REQUEST = "recommendation"
export const RELOCATION_REQUEST = "relocation"
export const SPORT_REQUEST = "sport"
export const BTRIP_REQUEST = "btrip"
export const CHILDREN_BIRTH_REQUEST = "children"
export const WEDDING_REQUEST = "wedding"
export const BELKA_BUGS_REQUEST = "bugs"
export const BOOK_REQUEST = "book"
export const OFFICE_APPLIANCES_REQUEST = "appliances"
export const PURCHASES_REQUEST = "purchases"
export const OOO_REQUEST = "ooo"
export const REVISION_SALARY_REQUEST = "salary"
export const PERSONAL_EXPENSES_REQUEST = "personal"
export const WORKPLACE_COST_REQUEST = "workplace"
