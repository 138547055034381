import { MobileDatePicker } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { Box, Button, Card, Chip, Grid, Link, Stack, TextField } from "@mui/material"
import { format, isValid } from "date-fns"
import React, { useEffect } from "react"
import {chooseInitRequest, getOrDefault, isExists} from "../../utils"
import { RELOCATION_REQUEST } from "../requestTypes"
import manageRequest from "./hoc/manageRequest"
import RequestInputStyle from "./MaterialStyles"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink"

const REQUIRED_FIELDS = [
    "relocationCountry",
    "relocationCity",
    "relocationDate",
    "numberOfPersons",
    "images"
]

const RelocationRequest = (props) => {
    useEffect(() => {
        const initRequest = {
            fullName: props.userProfile.normalized_full_name
                ? props.userProfile.normalized_full_name
                : "",
            office: props.findOfficeIfExist(props.userProfile.location.name),
            phone: props.userProfile.mobile_number,
            relocationCountry: "",
            relocationCity: "",
            relocationDate: "",
            numberOfPersons: "0",
            relationshipInfo: "",
            images: [],
            comment: ""
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))

        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));

        props.setRequiredFields(REQUIRED_FIELDS)
    }, [])

    const handleChangeEndDate = (date) => {
        if (isValid(date)) {
            props.setRequest({
                ...props.request,
                relocationDate: format(date, "yyyy-MM-dd")
            })
        }
    }

    return (
        <>
            {props.request &&
                <>
                    <RequestBar header={<><b>Система заявок:</b> компенсация затрат на переезд</>} />
                    <Box className="select_request">
                        <Card>
                        <Box component="form">
                            <Box sx={{ px: "5%" }}>
                                <RequestDescription>
                                    Привет!
                                    Через эту форму ты можешь оставить заявку на компенсацию релокационных расходов.
                                    Детально ознакомиться с условиями можно
                                    <RequestDescriptionLink page="BC/pages/3295248385" spaceBefore>
                                        здесь
                                    </RequestDescriptionLink>
                                    .
                                </RequestDescription>
                                <RequestInputStyle>
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        required
                                        fullWidth
                                        label="Страна переезда"
                                        name="relocationCountry"
                                        value={getOrDefault(props.request.relocationCountry)}
                                        helperText={(props.errorForm && props.isEmptyInput("relocationCountry"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("relocationCountry")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        required
                                        fullWidth
                                        label="Город переезда"
                                        name="relocationCity"
                                        value={getOrDefault(props.request.relocationCity)}
                                        helperText={(props.errorForm && props.isEmptyInput("relocationCity"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("relocationCity")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            showToolbar={false}
                                            disableFuture={true}
                                            label="Дата переезда"
                                            name="relocationDate"
                                            value={getOrDefault(props.request.relocationDate)}
                                            onChange={handleChangeEndDate}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    sx={{ width: "100%", mb: 3, background: "white" }}
                                                    required
                                                    helperText={(props.errorForm && props.isEmptyInput(
                                                        "relocationDate"))
                                                        ? "Поле не должно быть пустым."
                                                        : ""}
                                                    error={props.errorForm
                                                        ? props.isEmptyInput("relocationDate")
                                                        : false}
                                                />}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        required
                                        fullWidth
                                        type="number"
                                        label="Сколько человек с тобой переехало?"
                                        name="numberOfPersons"
                                        value={getOrDefault(props.request.numberOfPersons)}
                                        helperText={(props.errorForm && props.isEmptyInput("numberOfPersons"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("numberOfPersons")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        fullWidth
                                        label="Напиши степень родства с ними"
                                        name="relationshipInfo"
                                        value={getOrDefault(props.request.relationshipInfo)}
                                        onChange={props.handleChange}
                                    />
                                    <Box sx={{ py: 2 }}>
                                        <Box
                                            sx={{
                                                textAlign: "center",
                                                width: "300px",
                                                display: "block",
                                                margin: "0 auto"
                                            }}
                                        ><label htmlFor="upload-photo">
                                            <input
                                                style={{ display: "none" }}
                                                id="upload-photo"
                                                type="file"
                                                accept="image/*,application/pdf"
                                                multiple
                                                name="images"
                                                onChange={props.handleChangeFiles}
                                            />
                                            <Box>
                                                <Button
                                                    fullWidth
                                                    component="span"
                                                    variant="contained"
                                                >
                                                    Загрузить фото/скан чека*
                                                </Button>
                                                {(props.errorForm && props.isEmptyInput("images")) &&
                                                    <p className="file_loading_helper_text">
                                                        Необходимо загрузить фото/скан
                                                    </p>}
                                            </Box>
                                        </label></Box>
                                        <Grid
                                            container
                                            justifyContent="center"
                                        >
                                            <p className="file_loading_helper_text color_black">
                                                <b>Общий размер файлов не должен превышать 30MB</b>
                                            </p>
                                        </Grid>
                                        {isExists(props.request.images) &&
                                            <Stack
                                                sx={{ textAlign: "center", alignItems: "center", marginTop: 2 }}
                                                direction="column"
                                                spacing={1}
                                            >
                                                {props.request.images.map((image, index) =>
                                                    <Chip
                                                        sx={{ width: "50%", justifyContent: "space-between" }}
                                                        key={index}
                                                        label={image.name}
                                                        onDelete={() => props.handleDeleteImage(index)}
                                                    />
                                                )}
                                            </Stack>
                                        }
                                    </Box>
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        fullWidth
                                        multiline
                                        label="Комментарий"
                                        name="comment"
                                        value={getOrDefault(props.request.comment)}
                                        onChange={props.handleChange}
                                    />
                                </RequestInputStyle>
                            </Box>
                            {props.children}
                        </Box>
                        </Card>
                    </Box>
                </>
            }
        </>
    )
}

export default manageRequest(RelocationRequest, RELOCATION_REQUEST)
