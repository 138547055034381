import { MobileDatePicker } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import {Box, Button, Card, Chip, Grid, Link, MenuItem, Stack, TextField} from "@mui/material"
import { format, isValid } from "date-fns"
import React, { useEffect } from "react"
import {chooseInitRequest, getOrDefault, isExists} from "../../utils"
import {PERSONAL_EXPENSES_REQUEST, RELOCATION_REQUEST} from "../requestTypes"
import manageRequest, {CURRENCIES, SALARY_CURRENCIES, SelectProps} from "./hoc/manageRequest"
import RequestInputStyle from "./MaterialStyles"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink"

const REQUIRED_FIELDS = [
    "fullName",
    "expensesType",
    "purchaseDate",
    "price",
    "currency",
    "offerCurrency",
    "images"
]

const PersonalExpensesRequest = (props) => {
    useEffect(() => {
        const initRequest = {
            fullName: props.userProfile.normalized_full_name
                ? props.userProfile.normalized_full_name
                : "",
            office: props.findOfficeIfExist(props.userProfile.location.name),
            phone: props.userProfile.mobile_number,
            expensesType: "",
            purchaseDate: "",
            price: "0",
            currency: "",
            offerCurrency: "",
            images: [],
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))

        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));

        props.setRequiredFields(REQUIRED_FIELDS)
    }, [])

    const handleChangeEndDate = (date) => {
        if (isValid(date)) {
            props.setRequest({
                ...props.request,
                purchaseDate: format(date, "yyyy-MM-dd")
            })
        }
    }

    return (
        <>
            {props.request &&
                <>
                    <RequestBar header={<><b>Система заявок:</b> компенсация расходов за свой счет</>} />
                    <Box className="select_request">
                        <Card>
                        <Box component="form">
                            <Box sx={{ px: "5%" }}>
                                <RequestDescription>
                                    Привет! Через эту форму ты можешь оставить заявку на компенсацию расходов за свой
                                    счет. Обязательно убедись, что эти расходы соответствуют твоим корпоративным задачам
                                    и были сделаны в интересах компании.
                                </RequestDescription>
                                <RequestInputStyle>
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        required
                                        fullWidth
                                        label="Тип затрат"
                                        name="expensesType"
                                        value={getOrDefault(props.request.expensesType)}
                                        helperText={(props.errorForm && props.isEmptyInput("expensesType"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("expensesType")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            showToolbar={false}
                                            label="Дата затрат"
                                            name="purchaseDate"
                                            value={getOrDefault(props.request.purchaseDate)}
                                            onChange={handleChangeEndDate}
                                            disableFuture={true}

                                                renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    sx={{ width: "100%", mb: 3, background: "white" }}
                                                    required
                                                    helperText={(props.errorForm && props.isEmptyInput(
                                                        "purchaseDate"))
                                                        ? "Поле не должно быть пустым."
                                                        : "Если было несколько покупок, нужно указать дату последней"}
                                                    error={props.errorForm
                                                        ? props.isEmptyInput("purchaseDate")
                                                        : false}
                                                />}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        required
                                        fullWidth
                                        type="number"
                                        label="Сумма"
                                        name="price"
                                        value={getOrDefault(props.request.price)}
                                        helperText={(props.errorForm && props.isEmptyInput("price"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("price")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        required
                                        select
                                        SelectProps={SelectProps}
                                        label="Валюта расходов"
                                        value={props.request.currency ? props.request.currency : ""}
                                        onChange={props.handleChange}
                                        name="currency"
                                        className="request__input"
                                        sx={{mb: 3, mr:4}}
                                        error={props.errorForm ? props.isEmptyInput("currency") : false}
                                        helperText={(props.errorForm && props.isEmptyInput("currency")) ?
                                            "Поле не должно быть пустым." :
                                            ""}
                                    >
                                        {CURRENCIES.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        select
                                        SelectProps={SelectProps}
                                        label="Валюта ЗП"
                                        value={props.request.offerCurrency ? props.request.offerCurrency : ""}
                                        onChange={props.handleChange}
                                        name="offerCurrency"
                                        className="request__input"
                                        sx={{mb: 3}}
                                        error={props.errorForm ? props.isEmptyInput("offerCurrency") : false}
                                        helperText={(props.errorForm && props.isEmptyInput("offerCurrency")) ?
                                            "Поле не должно быть пустым." :
                                            "Здесь нужно укзать валюту в которой ты получаешь ЗП"}
                                    >
                                        {SALARY_CURRENCIES.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Box sx={{ py: 2 }}>
                                        <Box
                                            sx={{
                                                textAlign: "center",
                                                width: "300px",
                                                display: "block",
                                                margin: "0 auto"
                                            }}
                                        ><label htmlFor="upload-photo">
                                            <input
                                                style={{ display: "none" }}
                                                id="upload-photo"
                                                type="file"
                                                accept="image/*,application/pdf"
                                                multiple
                                                name="images"
                                                onChange={props.handleChangeFiles}
                                            />
                                            <Box>
                                                <Button
                                                    fullWidth
                                                    component="span"
                                                    variant="contained"
                                                >
                                                    Загрузить фото/сканы чеков*
                                                </Button>
                                                {(props.errorForm && props.isEmptyInput("images")) &&
                                                    <p className="file_loading_helper_text">
                                                        Необходимо загрузить фото/сканы чеков
                                                    </p>}
                                            </Box>
                                        </label></Box>
                                        <Grid
                                            container
                                            justifyContent="center"
                                        >
                                            <p className="file_loading_helper_text color_black">
                                                <b>Общий размер файлов не должен превышать 30MB</b>
                                            </p>
                                        </Grid>
                                        {isExists(props.request.images) &&
                                            <Stack
                                                sx={{ textAlign: "center", alignItems: "center", marginTop: 2 }}
                                                direction="column"
                                                spacing={1}
                                            >
                                                {props.request.images.map((image, index) =>
                                                    <Chip
                                                        sx={{ width: "50%", justifyContent: "space-between" }}
                                                        key={index}
                                                        label={image.name}
                                                        onDelete={() => props.handleDeleteImage(index)}
                                                    />
                                                )}
                                            </Stack>
                                        }
                                    </Box>
                                </RequestInputStyle>
                            </Box>
                            {props.children}
                        </Box>
                        </Card>
                    </Box>
                </>
            }
        </>
    )
}

export default manageRequest(PersonalExpensesRequest, PERSONAL_EXPENSES_REQUEST)
