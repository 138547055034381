import React, {useEffect} from "react";
import {chooseInitRequest, getOrDefault, isExists} from "../../utils";
import RequestBar from "./RequestBar";
import {Box, Button, Card, Chip, Grid, MenuItem, Stack, TextField} from "@mui/material";
import RequestDescription from "./RequestDescription";
import manageRequest, {SelectProps} from "./hoc/manageRequest";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {MobileDatePicker} from "@mui/lab";
import {CHILDREN_BIRTH_REQUEST, WEDDING_REQUEST} from "../requestTypes";
import {format, isValid} from "date-fns";
import RequestDescriptionLink from "./RequestDescriptionLink";

const REQUIRED_FIELDS = [
    "employeeFullName",
    "eventDate",
    "images",
    "congratulation"
]
const WeddingRequest = (props) => {
    useEffect(() => {
        const initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            location: props.findOfficeIfExist(props.userProfile.location.name.trim()),
            eventDate: "",
            images: [],
            congratulation: ""
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)
    }, [])

    const handleChangeEndDate = (date) => {
        if (isValid(date)) {
            props.setRequest({
                ...props.request,
                eventDate: format(date, "yyyy-MM-dd")
            })
        }
    }

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> Свадьба</>} />
            <Box className="select_request">
                <Card>
                    <Box component="form">
                        <Box sx={{ px: "5%" }}>
                            <RequestDescription>
                                Привет! Вся команда белки поздравляет тебя с радостным событием! Для получения подарка (10 Belcoins) нужно заполнить заявку ниже.
                            </RequestDescription>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    showToolbar={false}
                                    label="Дата события"
                                    name="eventDate"
                                    value={getOrDefault(props.request.eventDate)}
                                    onChange={handleChangeEndDate}
                                    disableFuture={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            sx={{ width: "100%", mb: 3, background: "white" }}
                                            required
                                            helperText={(props.errorForm && props.isEmptyInput(
                                                "eventDate"))
                                                ? "Поле не должно быть пустым."
                                                : ""}
                                            error={props.errorForm
                                                ? props.isEmptyInput("eventDate")
                                                : false}
                                        />}
                                />
                            </LocalizationProvider>
                            <Box sx={{ py: 2 }}
                               >
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        width: "300px",
                                        display: "block",
                                        margin: "0 auto"
                                    }}
                                ><label htmlFor="upload-photo">
                                    <input
                                        style={{ display: "none" }}
                                        id="upload-photo"
                                        type="file"
                                        accept="image/*,application/pdf"
                                        multiple
                                        name="images"
                                        onChange={props.handleChangeFiles}
                                    />
                                    <Box>
                                        <Button
                                            fullWidth
                                            component="span"
                                            variant="contained"
                                        >
                                            Загрузить фото/скан свидетельства*
                                        </Button>
                                        {(props.errorForm && props.isEmptyInput("images")) &&
                                            <p className="file_loading_helper_text">
                                                Необходимо загрузить фото/скан
                                            </p>}
                                    </Box>
                                </label></Box>
                                <Grid
                                    container
                                    justifyContent="center"
                                >
                                    <p className="file_loading_helper_text color_black">
                                        <b>Общий размер файлов не должен превышать 30MB</b>
                                    </p>
                                </Grid>
                                {isExists(props.request.images) &&
                                <Stack
                                    sx={{ textAlign: "center", alignItems: "center", marginTop: 2 }}
                                    direction="column"
                                    spacing={1}
                                >
                                    {props.request.images.map((image, index) =>
                                        <Chip
                                            sx={{ width: "50%", justifyContent: "space-between" }}
                                            key={index}
                                            label={image.name}
                                            onDelete={() => props.handleDeleteImage(index)}
                                        />
                                    )}
                                </Stack>
                                }
                            </Box>

                            <TextField
                                style={{
                                    textAlign: "left"
                                }}
                                required
                                select
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Хотели бы вы получить поздравление в общем канале #belka"
                                SelectProps={SelectProps}
                                className="request__input"
                                name="congratulation"
                                value={props.request.congratulation ? props.request.congratulation : ""}
                                helperText={(props.errorForm && props.isEmptyInput("congratulation"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("congratulation") : false}
                                onChange={props.handleChange}
                            >
                                <MenuItem value={"Да"}>Да</MenuItem>
                                <MenuItem value={"Нет"}>Нет</MenuItem>
                            </TextField>
                        </Box>
                        {props.children}
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default manageRequest(WeddingRequest, WEDDING_REQUEST)