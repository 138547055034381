import {DialogContentText, Link, Typography} from "@mui/material";

export default function CommonResponse(props) {
    return (
        props.response &&
        <DialogContentText
            id="alert-dialog-description"
            sx={{textAlign: "center", pt: 3}}
        >
            {props.isRecommendation ? 'Спасибо! Рекомендация взята в работу, после завершения рассмотрения кандидата с тобой свяжется рекрутер.' : 'Заявка успешно обработана!'}
            &#160;<br/>
            <Link
                href={props.response.link}
                underline="hover"
                target="_blank"
            >
                Ссылка на задачу :)
            </Link>
        </DialogContentText>
    );
}