import * as Sentry from "@sentry/react"
import {useDispatch, useSelector} from "react-redux"
import { useNavigate } from "react-router-dom"
import "./App.css"
import AuthenticatedRouting from "./components/routes/AuthenticatedRouting"
import UnauthenticatedRouting from "./components/routes/UnauthenticatedRouting"
import {logoutUser, setUser} from "./redux/actions"
import {keycloak} from "./keycloak-config";
import {ReactKeycloakProvider } from "@react-keycloak/web";
import Loading from "./components/request_components/Loading";

const App = () => {
    const userState = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    function kcEvent(event) {
        if(event === 'onAuthLogout') {
            logoutUser();
            keycloak.logout();
        }

        if(event === 'onAuthRefreshError') {

        }

        if(event === 'onAuthRefreshSuccess') {
            sessionStorage.setItem('kctoken', keycloak.token);
        }

        if(event === 'onAuthSuccess') {
            if (keycloak.authenticated) {
                sessionStorage.setItem('kctoken', keycloak.token);
                dispatch(setUser(keycloak.idTokenParsed.preferred_username));
                Sentry.setUser({email: keycloak.idTokenParsed.preferred_username})

            } else {
                logoutUser();
                navigate("/")
            }
        }

    }

    return (

        <>
            <ReactKeycloakProvider
                LoadingComponent={<Loading />}
                onEvent={kcEvent}
                initOptions={{onLoad: 'check-sso', checkLoginIframeInterval: 1000}}
                authClient={keycloak}
            >
                { userState !== null ?
                    <AuthenticatedRouting /> :
                    <UnauthenticatedRouting />
                }

            </ReactKeycloakProvider>
        </>
    )
}

export default App
