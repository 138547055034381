import {DialogContentText, Typography} from "@mui/material";

export default function SalaryRevisionResponse(props) {
    return (
        props.result &&
        <DialogContentText
            id="alert-dialog-description"
            sx={{textAlign: "center", pt: 3}}
        >
            <Typography>
                <strong> Следующий пересмотр возможен с: </strong>{props.result.startDate}
            </Typography>
            <Typography>
                <strong> Максимальный размер пересмотра: </strong>{props.result.maxDiff}
            </Typography>
            <Typography>
                <strong> Максимальная зарплата с учетом пересмотра:</strong> {props.result.maxNewSalary}
            </Typography>
        </DialogContentText>
    );
}