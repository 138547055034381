import manageRequest, {SelectProps} from "./hoc/manageRequest";
import { Box, Card, MenuItem, TextField } from "@mui/material"
import {RECOMMENDATION_REQUEST} from "../requestTypes";
import React, {useEffect} from "react";
import {chooseInitRequest} from "../../utils";
import RequestBar from "./RequestBar";
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink"

const REQUIRED_FIELDS = ["employeeFullName", "candidateFullName", "candidatePosition", "contacts", "social", "refer", "level", "inSearch", "relocation"]

function EmployeeRecommendationRequest(props) {

    useEffect(() => {
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        let initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            candidateFullName: "",
            candidatePosition: "",
            cvLink: "",
            contacts: "",
            social: "",
            portfolio: "",
            feedback: "",
            refer: "",
            level:"",
            inSearch:"",
            relocation:""
        }
        props.setInitRequest(()=>()=>props.setRequest(initRequest));
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)
    }, [])

    return <>
        <RequestBar header={<><b>Система заявок:</b> рекомендация сотрудника</>} />
        <Box className="select_request">
            <Card>
            <Box component="form" noValidate>
                <Box sx={{px: "5%"}}>
                    <RequestDescription>
                        Практика показала, что самые сильные специалисты приходят к нам через реферальную программу.
                        Если у тебя на примете есть классный кандидат — обязательно заполни форму ниже.
                        Мы проведем все этапы собеседования на скорости х2 и в случае успеха — выплатим тебе бонус.
                        <RequestDescriptionLink page="BC/pages/14483515" spaceBefore spaceAfter>
                            Подробнее
                        </RequestDescriptionLink>
                        о реферальной программе.
                    </RequestDescription>
                    <TextField
                        required
                        label="ФИО кандидата, которого рекомендуешь"
                        name="candidateFullName"
                        value={props.request.candidateFullName ? props.request.candidateFullName : ""}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                        error={props.errorForm ? props.isEmptyInput("candidateFullName") : false}
                        helperText={(props.errorForm && props.isEmptyInput("candidateFullName")) ? "Поле не должно быть пустым." : ""}
                    />
                    <TextField
                        required
                        label="На какую вакансию рекомендуешь?"
                        name="candidatePosition"
                        value={props.request.candidatePosition ? props.request.candidatePosition : ""}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                        error={props.errorForm ? props.isEmptyInput("candidatePosition") : false}
                        helperText={(props.errorForm && props.isEmptyInput("candidatePosition")) ? "Поле не должно быть пустым." : ""}
                    />
                    <TextField
                        label="CV кандидата (ссылка)"
                        name="cvLink"
                        value={props.request.cvLink ? props.request.cvLink : ""}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                    />
                    <TextField
                        required
                        label="Контакты кандидата"
                        name="contacts"
                        value={props.request.contacts ? props.request.contacts : ""}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                        error={props.errorForm ? props.isEmptyInput("contacts") : false}
                        helperText={(props.errorForm && props.isEmptyInput("contacts")) ? "Поле не должно быть пустым." : ""}
                    />
                    <TextField
                        required
                        label="Соц. сети кандидата (Linkedin, FB и др.)"
                        name="social"
                        value={props.request.social ? props.request.social : ""}
                        multiline
                        rows={3}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                        error={props.errorForm ? props.isEmptyInput("social") : false}
                        helperText={(props.errorForm && props.isEmptyInput("social")) ? "Поле не должно быть пустым." : ""}
                    />
                    <TextField
                        label="Портфолио (для арт-позиций)"
                        name="portfolio"
                        value={props.request.portfolio ? props.request.portfolio : ""}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                    />
                    <TextField
                        label="Пару слов о кандидате (твой личный фидбек)"
                        name="feedback"
                        value={props.request.feedback ? props.request.feedback : ""}
                        fullWidth
                        multiline
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                    />
                    <TextField
                        required
                        select
                        SelectProps={SelectProps}
                        label="Можно ли на тебя ссылаться?"
                        name="refer"
                        value={props.request.refer ? props.request.refer : ""}
                        onChange={props.handleChange}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        error={props.errorForm ? props.isEmptyInput("refer") : false}
                        helperText={(props.errorForm && props.isEmptyInput("refer")) ? "Поле не должно быть пустым." : ""}
                    >
                        <MenuItem value={"Да"}>Да</MenuItem>
                        <MenuItem value={"Нет"}>Нет</MenuItem>
                    </TextField>
                    <TextField
                        required
                        select
                        SelectProps={SelectProps}
                        label="Как ты оцениваешь уровень кандидата?"
                        name="level"
                        value={props.request.level ? props.request.level : ""}
                        onChange={props.handleChange}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        error={props.errorForm ? props.isEmptyInput("level") : false}
                        helperText={(props.errorForm && props.isEmptyInput("level")) ? "Поле не должно быть пустым." : ""}
                    >
                        <MenuItem value={"Высокий"}>Высокий</MenuItem>
                        <MenuItem value={"Нормальный"}>Нормальный</MenuItem>
                        <MenuItem value={"Затрудняюсь ответить"}>Затрудняюсь ответить</MenuItem>
                    </TextField>
                    <TextField
                        required
                        select
                        SelectProps={SelectProps}
                        label="Находится ли кандидат в поиске в работы?"
                        name="inSearch"
                        value={props.request.inSearch ? props.request.inSearch : ""}
                        onChange={props.handleChange}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        error={props.errorForm ? props.isEmptyInput("inSearch") : false}
                        helperText={(props.errorForm && props.isEmptyInput("inSearch")) ? "Поле не должно быть пустым." : ""}
                    >
                        <MenuItem value={"Да"}>Да</MenuItem>
                        <MenuItem value={"Нет"}>Нет</MenuItem>
                        <MenuItem value={"Затрудняюсь ответить"}>Затрудняюсь ответить</MenuItem>
                    </TextField>
                    <TextField
                        required
                        label="Локация кандидата. Готовность к релокейту, если находится в стране, где не представлена компания"
                        name="relocation"
                        value={props.request.relocation ? props.request.relocation : ""}
                        multiline
                        rows={3}
                        fullWidth
                        sx={{mb: 3, background: "white"}}
                        onChange={props.handleChange}
                        error={props.errorForm ? props.isEmptyInput("relocation") : false}
                        helperText={(props.errorForm && props.isEmptyInput("relocation")) ? "Поле не должно быть пустым." : ""}
                    />
                </Box>
                {props.children}
            </Box>
            </Card>
        </Box></>
}

export default manageRequest(EmployeeRecommendationRequest, RECOMMENDATION_REQUEST)
