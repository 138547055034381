import {Box, Card, MenuItem, Stack, TextField, Typography} from "@mui/material"
import { format, isValid } from "date-fns"
import React, {useEffect, useState} from "react"
import {chooseInitRequest, getOrDefault, isExists, isUserAllowedForWorkplace, userIsLead} from "../../utils"
import manageRequest, {
    LOCATIONS_RU,
    SALARY_CURRENCIES,
    SelectProps
} from "./hoc/manageRequest"
import RequestInputStyle from "./MaterialStyles"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"
import {WORKPLACE_COST_REQUEST} from "../requestTypes";

const REQUIRED_FIELDS = [
    "fullName",
    "salary",
    "currency",
    "location",
]

const WorkplaceCostRequest = (props) => {
    const [requestUnavailable, setRequestUnavailable] = useState(false)

    useEffect(() => {
        const initRequest = {
            fullName: props.userProfile.normalized_full_name
                ? props.userProfile.normalized_full_name
                : "",
            office: props.findOfficeIfExist(props.userProfile.location.name),
            phone: props.userProfile.mobile_number,
            salary: "0",
            currency: "",
            location: "",
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))

        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));

        props.setRequiredFields(REQUIRED_FIELDS)
        setRequestUnavailable(!isUserAllowedForWorkplace(props.userProfile)&&!userIsLead(props.userProfile))

    }, [])

    return (
        <>
            {props.request &&
                <>
                    <RequestBar header={<><b>Система заявок:</b> стоимость рабочего места</>} />
                    <Box className="select_request">
                        {!requestUnavailable ?
                        <Card>
                        <Box component="form">
                            <Box sx={{ px: "5%" }}>
                                <RequestDescription>
                                    Инструмент для лидов, помогает рассчитать стоимость рабочего места штатного
                                    сотрудника в зависимости от его заработной платы и страны трудоустройства
                                    (инструмент учитывает различный режим налогообложения и накладные расходы в локациях)
                                </RequestDescription>
                                <RequestInputStyle>
                                    <TextField
                                        sx={{ mb: 3, background: "white" }}
                                        required
                                        fullWidth
                                        type="number"
                                        label="Введите размер оффера"
                                        name="salary"
                                        value={getOrDefault(props.request.salary)}
                                        helperText={(props.errorForm && props.isEmptyInput("salary"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("salary")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        required
                                        select
                                        SelectProps={SelectProps}
                                        label="Выберите валюту"
                                        value={props.request.currency ? props.request.currency : ""}
                                        onChange={props.handleChange}
                                        name="currency"
                                        className="request__input"
                                        sx={{mb: 3, mr:4}}
                                        error={props.errorForm ? props.isEmptyInput("currency") : false}
                                        helperText={(props.errorForm && props.isEmptyInput("currency")) ?
                                            "Поле не должно быть пустым." :
                                            ""}
                                    >
                                        {SALARY_CURRENCIES.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        select
                                        SelectProps={SelectProps}
                                        label="Выберите страну трудоустройства"
                                        value={props.request.location ? props.request.location : ""}
                                        onChange={props.handleChange}
                                        name="location"
                                        className="request__input"
                                        sx={{mb: 3}}
                                        error={props.errorForm ? props.isEmptyInput("location") : false}
                                        helperText={(props.errorForm && props.isEmptyInput("location")) ?
                                            "Поле не должно быть пустым." :
                                            ""}
                                    >
                                        {LOCATIONS_RU.map((location) => (
                                            (location !== "РФ" && location !== "Польша") &&
                                            <MenuItem
                                                key={location}
                                                value={location}
                                            >
                                                {location}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </RequestInputStyle>
                            </Box>
                            {props.children}
                        </Box>
                        </Card>:
                            <Card sx={{m: 4}}>
                                <Typography variant={"h6"} sx={{m: 4}}>
                                    Этот инструмент доступен только лидам, департаментам Finance и People operations
                                </Typography>
                            </Card>
                        }
                    </Box>
                </>
            }
        </>
    )
}

export default manageRequest(WorkplaceCostRequest, WORKPLACE_COST_REQUEST)
