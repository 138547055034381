import { Box, Button, Card, Chip, Grid, Link, MenuItem, Stack, TextField } from "@mui/material"
import moment from "moment"
import React, { useEffect, useState } from "react"
import {chooseInitRequest, getDiffDateByMonth, isExists} from "../../utils"
import { ENGLISH_LANGUAGE } from "../requestTypes"
import manageRequest, { CURRENCIES, SelectProps } from "./hoc/manageRequest"
import RequestInputStyle from "./MaterialStyles"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink"
import WarningDialog from "./WarningDialog"
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {MobileDatePicker} from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const REQUIRED_FIELDS = ["fullName", "courseLink", "price", "currency", "images", "purchaseDate"]

const EnglishLanguageCompensationRequest = (props) => {
    const [requestUnavailable, setRequestUnavailable] = useState(false)

    useEffect(() => {
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        let initRequest = {
            fullName: props.userProfile.normalized_full_name
                ? props.userProfile.normalized_full_name
                : "",
            office: props.findOfficeIfExist(props.userProfile.location.name.trim()),
            courseLink: "",
            schoolName: "",
            price: "",
            currency: props.findCurrencyByLocation(props.userProfile.location.name.trim()),
            images: [],
            comment: "",
            purchaseDate: ""
        }
        props.setInitRequest(()=> () => props.setRequest(initRequest));
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));

        props.setRequiredFields(REQUIRED_FIELDS)

        setRequestUnavailable(
            isExists(props.userProfile.hired_on)
                ? getDiffDateByMonth(moment(), new Date(props.userProfile.hired_on)) <= 3
                : false
        )
    }, [])


    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> английский язык</>} />
            <Box className="select_request">
                <Card>
                <Box component="form">
                    <Box sx={{px: "5%"}}>
                        <RequestDescription>
                            Как принято говорить — ученье свет!
                            Мы с этим абсолютно согласны, поэтому компенсируем 50% расходов на обучение.
                            Для этого заполни, пожалуйста, заявку ниже.
                            Все детали компенсации мы описали
                            <RequestDescriptionLink page="BC/pages/359596240" spaceBefore>
                                здесь
                            </RequestDescriptionLink>
                            .
                        </RequestDescription>
                        <TextField
                            required
                            fullWidth
                            sx={{mb: 3, background: "white"}}
                            label="Ссылка на курс"
                            name="courseLink"
                            disabled={requestUnavailable}
                            value={props.request.courseLink ? props.request.courseLink : ""}
                            helperText={(props.errorForm && props.isEmptyInput("courseLink"))
                                ? "Поле не должно быть пустым."
                                : ""}
                            error={props.errorForm ? props.isEmptyInput("courseLink") : false}
                            onChange={props.handleChange}

                        />
                        <TextField
                            required
                            fullWidth
                            sx={{mb: 3, background: "white"}}
                            label="Название школы"
                            name="schoolName"
                            disabled={requestUnavailable}
                            value={props.request.schoolName ? props.request.schoolName : ""}
                            helperText={(props.errorForm && props.isEmptyInput("schoolName"))
                                ? "Поле не должно быть пустым."
                                : ""}
                            error={props.errorForm ? props.isEmptyInput("schoolName") : false}
                            onChange={props.handleChange}

                        />
                        <RequestInputStyle>
                            <TextField
                                required
                                type="number"
                                sx={{mb: 3, mr: "4%"}}
                                label="Стоимость обучения"
                                name="price"
                                className="request__input"
                                disabled={requestUnavailable}
                                value={props.request.price ? props.request.price : ""}
                                helperText={(props.errorForm && props.isEmptyInput("price"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("price") : false}
                                onChange={props.handleChange}
                            />
                            <TextField
                                required
                                select
                                sx={{mb: 3}}
                                label="Валюта"
                                SelectProps={SelectProps}
                                className="request__input"
                                name="currency"
                                disabled={requestUnavailable}
                                value={props.request.currency ? props.request.currency : ""}
                                helperText={(props.errorForm && props.isEmptyInput("currency"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("currency") : false}
                                onChange={props.handleChange}
                            >
                                {CURRENCIES.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </RequestInputStyle>
                        <Box sx={{py: 2}}>
                            <Box sx={{
                                textAlign: "center",
                                width: "300px",
                                display: "block",
                                margin: "0 auto"
                            }}>
                                <label htmlFor="upload-photo">
                                    <input
                                        id="upload-photo"
                                        type="file"
                                        multiple
                                        style={{display: "none"}}
                                        accept="image/*,application/pdf"
                                        name="images"
                                        onChange={props.handleChangeFiles}
                                    />
                                    <Button
                                        fullWidth
                                        component="span"
                                        variant="contained"
                                        disabled={requestUnavailable}
                                    >
                                        Загрузить фото/скан чека*
                                    </Button>
                                    {(props.errorForm && props.isEmptyInput("images")) &&
                                    <p className="file_loading_helper_text">
                                        Необходимо загрузить фото/скан
                                    </p>}
                                </label>
                            </Box>
                            <Grid container justifyContent="center">
                                <p className="file_loading_helper_text color_black">
                                    <b>Общий размер файлов не должен превышать 30MB</b>
                                </p>
                            </Grid>
                            {props.request.images !== undefined &&
                            <Stack
                                direction="column"
                                spacing={1}
                                sx={{textAlign: "center", alignItems: "center"}}
                            >
                                {props.request.images.map((image, index) =>
                                    <Chip
                                        key={index}
                                        sx={{width: "50%", justifyContent: "space-between"}}
                                        label={image.name}
                                        onDelete={() => props.handleDeleteImage(index)}
                                    />
                                )}
                            </Stack>
                            }
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputFormat="dd.MM.yyyy"
                                label="Дата оплаты"
                                name="paymentDate"
                                type="date"
                                disableFuture={true}
                                showToolbar={false}
                                value={props.request.purchaseDate ? props.request.purchaseDate : ""}
                                onChange={(date) => props.handleChangeDate(date, "purchaseDate")}
                                renderInput={(params) => <TextField {...params} name="birthDate"
                                                                    required
                                                                    error={props.errorForm ? props.isEmptyInput("purchaseDate") : false}
                                                                    helperText={(props.errorForm && props.isEmptyInput("purchaseDate")) ? "Поле не должно быть пустым." : ""}
                                                                    className="request__input"
                                                                    sx={{mr: "3%", mb: 3, width:220}}/>}
                            />
                        </LocalizationProvider>
                        <TextField
                            fullWidth
                            multiline
                            sx={{mb: 3, background: "white"}}
                            label="Комментарий"
                            name="comment"
                            disabled={requestUnavailable}
                            value={props.request.comment ? props.request.comment : ""}
                            onChange={props.handleChange}
                        />
                    </Box>
                    {props.children}
                </Box>
                </Card>
            </Box>
            <WarningDialog open={requestUnavailable} onClose={() => setRequestUnavailable(false)}>
                Ты проработал в компании меньше 3 месяцев.<br />
                Отправить заявку невозможно.<br />
                <Link
                    href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/359596240"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Прочитать подробнее: Confluence
                </Link>
            </WarningDialog>
        </>
    )
}

export default manageRequest(EnglishLanguageCompensationRequest, ENGLISH_LANGUAGE)
