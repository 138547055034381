import {AUTH_USER, REQUEST_TYPE} from "./types";

const initialState = {
    user: null,
    requestType: null
};
export const rootReducer = (state = initialState, action) =>  {

    switch(action.type) {
        case AUTH_USER: {
            return {user: action.payload} ;
        }
        case REQUEST_TYPE: return {"requestType": action.payload} ;
        default: return state;
    }
}