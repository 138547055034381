import {Box, Card, Link, styled, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import RequestBar from "./request_components/RequestBar"
import {
    BTRIP_REQUEST,
    CONFERENCE_REQUEST,
    DEVICE_REQUEST,
    EDUCATION_REQUEST,
    ENGLISH_LANGUAGE,
    RECOMMENDATION_REQUEST,
    RELOCATION_REQUEST,
    SPORT_REQUEST,
    CHILDREN_BIRTH_REQUEST,
    BELKA_BUGS_REQUEST,
    WEDDING_REQUEST,
    BOOK_REQUEST,
    OFFICE_APPLIANCES_REQUEST,
    PURCHASES_REQUEST,
    REVISION_SALARY_REQUEST, PERSONAL_EXPENSES_REQUEST, WORKPLACE_COST_REQUEST, OOO_REQUEST
} from "./requestTypes"
import {useSelector} from "react-redux";
import {CONFIGS} from "../constants";
import {isUserAllowedForWorkplace, userAllowedForWorkplace, userIsLead} from "../utils";

export default function SelectRequestForm() {
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState(null)
    const [userLead, setUserLead] = useState(false)
    const [userAllowedForWorkplace, setUserAllowedForWorkplace] = useState(false)


    const user = useSelector((state) => state.user)


    useEffect(() => {
        if (user) {
            fetch(`${CONFIGS.baseUrl}/api/v1/employees/peopleforce`,
                {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${sessionStorage.getItem("kctoken")
                        }`,
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setUserProfile(result)
                })
                .catch(() => {
                    setUserProfile({})
                })
        }
    }, [user]);

    useEffect(() => {
        if (userProfile) {
            if (userProfile.custom_fields !== null) {
                const isLead = userIsLead(userProfile);
                setUserLead(isLead)
                const allowedForWorkplace = isUserAllowedForWorkplace(userProfile)
                setUserAllowedForWorkplace(isLead || allowedForWorkplace)
            }
        }
    }, [userProfile]);


    const SelectRequestFormStyle = styled('div')(({theme}) => ({
        [theme.breakpoints.down('iphonePRO')]: {
            ".select_request": {
                "& .select_request__list": {
                    justifyContent: "space-evenly"
                }
            }
        },
        [theme.breakpoints.down('tablet')]: {
            ".select_request": {
                "& .select_request__list": {
                    justifyContent: "space-evenly",
                    "& .select_request_form__card": {
                        padding: "12px 12px 12px 12px"
                    },
                    "& .select_request_form__card_container": {
                        width: "140px"
                    }
                }
            }
        }
    }));

    return (

        <>
            <RequestBar header={<b>ИТ-Помощник</b>}/>
            <SelectRequestFormStyle>
                <Box className="select_request">
                    <h2>Компенсации</h2>

                    <Box className="select_request__list" sx={{display: "flex", flexWrap: "wrap", justifyContent: ""}}>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${SPORT_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__sport_icon "/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${SPORT_REQUEST}`}>Компенсация спорта</Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${EDUCATION_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__education_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${EDUCATION_REQUEST}`}>Компенсация обучения</Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${ENGLISH_LANGUAGE}`)}>
                                <div className="select_request_form__icon select_request_form__english_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${ENGLISH_LANGUAGE}`}>Английский язык
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${RELOCATION_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__relocation_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${RELOCATION_REQUEST}`}>Компенсация затрат на переезд
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${CHILDREN_BIRTH_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__children-icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${CHILDREN_BIRTH_REQUEST}`}>Рождение ребенка
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}}  className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${PERSONAL_EXPENSES_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__shop-icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${PERSONAL_EXPENSES_REQUEST}`}>Расходы за свой счет
                                </Link>
                            </Typography>
                        </Box>
                    </Box>

                    <h2>Заявки</h2>
                    <Box className="select_request__list" sx={{display: "flex", flexWrap: "wrap", justifyContent: ""}}>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={() => navigate(`/request/${DEVICE_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__device_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link
                                    underline="none"
                                    color="inherit"
                                    component={RouterLink}
                                    to={`/request/${DEVICE_REQUEST}`}
                                >
                                    Заказ ПО и оборудования
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${CONFERENCE_REQUEST}`)}>
                                <div className="select_request_form__conference_icon select_request_form__icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${CONFERENCE_REQUEST}`}>Заявка на участие в конференции</Link>
                            </Typography>
                        </Box>

                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${BTRIP_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__btrip_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${ENGLISH_LANGUAGE}`}>Командировка
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${RECOMMENDATION_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__recommendation_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${RECOMMENDATION_REQUEST}`}>Рекомендация сотрудника
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${BELKA_BUGS_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__bugs-icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${BELKA_BUGS_REQUEST}`}>Belka Bugs
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${BOOK_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__book_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${BOOK_REQUEST}`}>Заказать книгу
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${OFFICE_APPLIANCES_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__office_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${OFFICE_APPLIANCES_REQUEST}`}>Заказать канцелярию
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${PURCHASES_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__education_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${PURCHASES_REQUEST}`}>Закупки
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${OOO_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__relocation_icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${OOO_REQUEST}`}>Работа вне Грузии
                                </Link>
                            </Typography>
                        </Box>
                    </Box>

                    <h2>Belcoins</h2>
                    <Box className="select_request__list" sx={{display: "flex", flexWrap: "wrap", justifyContent: ""}}>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => navigate(`/request/${WEDDING_REQUEST}`)}>
                                <div className="select_request_form__icon select_request_form__wedding-icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit" component={RouterLink}
                                      to={`/request/${WEDDING_REQUEST}`}>Свадьба
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                            <Card className="select_request_form__card"
                                  onClick={(e) => window.location.href = 'https://belcoinshop.belka-games.com/'}>
                                <div className="select_request_form__icon select_request_form__shop-icon"/>
                            </Card>
                            <Typography sx={{pt: "10px"}} variant="h6">
                                <Link underline="none" color="inherit"> Belcoinshop
                                </Link>
                            </Typography>
                        </Box>
                    </Box>

                    {userAllowedForWorkplace && <Box>

                        <h2>Сервисы</h2>
                        <Box className="select_request__list"
                             sx={{display: "flex", flexWrap: "wrap", justifyContent: ""}}>
                            {/*userLead &&<Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                                <Card className="select_request_form__card"
                                      onClick={(e) => navigate(`/request/${REVISION_SALARY_REQUEST}`)}>
                                    <div className="select_request_form__icon select_request_form__device_icon"/>
                                </Card>
                                <Typography sx={{pt: "10px"}} variant="h6">
                                    <Link underline="none" color="inherit" component={RouterLink}
                                          to={`/request/${REVISION_SALARY_REQUEST}`}>Пересмотр ЗП
                                    </Link>
                                </Typography>
                            </Box>*/}
                             <Box sx={{mx: 2, my: 2}} className="select_request_form__card_container">
                                <Card className="select_request_form__card"
                                      onClick={(e) => navigate(`/request/${WORKPLACE_COST_REQUEST}`)}>
                                    <div className="select_request_form__icon select_request_form__education_icon"/>
                                </Card>
                                <Typography sx={{pt: "10px"}} variant="h6">
                                    <Link underline="none" color="inherit" component={RouterLink}
                                          to={`/request/${WORKPLACE_COST_REQUEST}`}>Стоимость рабочего места
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>}
                </Box>

            </SelectRequestFormStyle>
        </>
    );
}
