import { Box, Typography } from "@mui/material"
import React from "react"

const RequestDescription = (props) => (
    <Box sx={{ textAlign: "left", mb: 3, mt: 3 }}>
        <Typography variant="body1">
            {props.children}
        </Typography>
    </Box>
)

export default RequestDescription
