import React from "react"
import {Box, Button, styled} from "@mui/material";
import {keycloak} from "../keycloak-config";

export default function LoginForm() {

    const LoginStyle = styled('div')(({theme}) => ({
        [theme.breakpoints.down('iphonePRO')]: {

            paddingTop: "20%",
        }
    }));

    const handleClickLogin = () => {
        keycloak.login()
    }

    return (

        <LoginStyle>
            <Box sx={{display: "flex", justifyContent: "center", pt: "10%"}}>
                <form className="login-content">
                    <h1 className="login-content__header">Система заявок</h1>
                    <Button variant="contained" onClick={() => handleClickLogin()}>Login</Button>
                </form>
            </Box>
        </LoginStyle>
    )
}


