import {Navigate, Route, Routes} from "react-router-dom";
import LoginForm from "../LoginForm";

function UnauthenticatedRouting() {
    return (
        <Routes>
            <Route exact path="/" element={<LoginForm />} />
            <Route path="*" element={<Navigate to="/" /> } />
        </Routes>
    );
}

export default UnauthenticatedRouting;