import { Box, Button, Divider, styled } from "@mui/material"
import * as Sentry from "@sentry/react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {CITY_PF_UUID, CONFIGS, COUNTRY_PF_UUID, HR_PF_UUID} from "../../../constants"
import { findValueInArrayCF, isEmptyArray, isEmptyValue, isExists, isValidDate } from "../../../utils"
import BrokenProfilePopup from "../BrokenProfilePopup"
import Loading from "../Loading"
import RequestResponseStatus from "../RequestResponseStatus"
import {format} from "date-fns";
import {REVISION_SALARY_REQUEST, WORKPLACE_COST_REQUEST} from "../../requestTypes";

export const OFFICE_BY_LOCATION = Object.freeze({
    "Saint Petersburg": "РФ",
    "Kyiv": "Украина",
    "Minsk": "РБ",
    "Limassol": "Кипр",
    "Vilnius": "Литва",
    "Krakov": "Польша",
    "Budva": "Черногория",
    "Tbilisi": "Грузия"
})

export const LOCATIONS_RU = Object.freeze(Object.values(OFFICE_BY_LOCATION))

export const CURRENCY_BY_LOCATION = Object.freeze({
    "Saint Petersburg": "RUB",
    "Kyiv": "UAH",
    "Minsk": "BYN",
    "Limassol": "EUR",
    "Vilnius": "EUR",
    "Krakov": "PLN",
    "Budva": "EUR",
    "Tbilisi": "GEL"
})

export const CURRENCIES = Object.freeze([
    { value: "EUR", name: "EUR" },
    { value: "USD", name: "USD" },
    { value: "RUB", name: "RUB" },
    { value: "BYN", name: "BYN" },
    { value: "UAH", name: "UAH" },
    { value: "PLN", name: "PLN" },
    { value: "GEL", name: "GEL" }
])

export const SALARY_CURRENCIES = Object.freeze([
    { value: "EUR", name: "EUR" },
    { value: "USD", name: "USD" }
])

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    disableScrollLock: true
}

export const SelectProps = {
    "MenuProps": MenuProps
}

const manageRequest = (Request, requestType) => {
    return () => {
        const [request, setRequest] = useState({})
        const [initRequest, setInitRequest] = useState(() => () => {
        })
        const [userProfile, setUserProfile] = useState(null)
        const [hrName, setHrName] = useState("Ivan Gorchakov")
        const [requestStatus, setRequestStatus] = useState(null)
        const [requiredFields, setRequiredFields] = useState([])
        const [loading, setLoading] = useState(false)
        const [loadingUserProfile, setLoadingUserProfile] = useState(true)
        const [errorForm, setErrorForm] = useState(false)
        const [brokenProfile, setBrokenProfile] = useState([])

        const user = useSelector((state) => state.user)

        const location = useLocation()

        const navigate = useNavigate()

        const back = () => {
            navigate("/request")
        }

        const clearRequest = () => {
            window.sessionStorage.removeItem(window.location.pathname)
            initRequest()
        }

        const RequestButtonsStyle = styled("div")(({ theme }) => ({
            [theme.breakpoints.down("iphonePRO")]: {
                ".request__buttons_container": {
                    justifyContent: "center"
                }
            }
        }))

        const handleStartDateChange = (date) => {
            const value = format(date, "yyyy-MM-dd")
            const newRequest = Object.assign({}, request)
            newRequest.startOfEvent = value
            setRequest(newRequest)

        }
        const handleEndDateChange = (date) => {
            const value = format(date, "yyyy-MM-dd")
            const newRequest = Object.assign({}, request)
            newRequest.endOfEvent = value
            setRequest(newRequest)
        }

        const handleChangeCheckBox = (event, fieldName) => {
            const newRequest = Object.assign({}, request)
            newRequest[fieldName] = event.target.checked
            setRequest(newRequest)
        }

        const handleChange = (event) => {
            const name = event.target.name
            const newRequest = Object.assign({}, request)
            newRequest[name] = event.target.value
            setRequest(newRequest)
        }

        const handleChangeCustom = (name, value, name1, value1) => {
            const newRequest = Object.assign({}, request)
            newRequest[name] = value
            newRequest[name1] = value1
            setRequest(newRequest)
        }

        const handleChangeCustomOne = (name, value) => {
            const newRequest = Object.assign({}, request)
            newRequest[name] = value
            setRequest(newRequest)
        }

        const handleChangeFiles = (event) => {
            const newRequest = Object.assign({}, request)
            const files = event.target.files
            if (files.length > 0) {
                let currentImages = newRequest.images
                if (currentImages === undefined) {
                    currentImages = newRequest.images = []
                }
                currentImages = currentImages.concat(Array.from(files))
                newRequest.images = currentImages
                setRequest(newRequest)
            }
            event.currentTarget.value = null
        }

        const handleChangeDate = (date, field) => {
            setRequest({
                ...request,
                [field]: isValidDate(date) ? date.toISOString() : ""
            })
        }

        const handleDeleteImage = (value) => {
            const newRequest = Object.assign({}, request)
            newRequest.images = newRequest.images.filter((image, index) => index !== value)
            setRequest(newRequest)
        }

        const isEmptyInput = (field) => {
            const value = request[field]
            if (!isExists(value)) return true
            if (Array.isArray(value)) return isEmptyArray(value)
            if (typeof value === "string") return isEmptyValue(value)
            if (typeof value === "object") return value instanceof Date
                ? isNaN(value.getTime())
                : Object.keys(value).length === 0
            return false
        }

        const isNotEmptyInput = (field) => !isEmptyInput(field)

        const handleSubmissionIfFormComplete = () => {
            console.log('requiredFields', requiredFields)
            console.log('request', request)
            request.employeeFullName = userProfile.normalized_full_name ? userProfile.normalized_full_name : "";
            const getFormData = (object) => {
                const formData = new FormData()
                Object.keys(object).forEach(key => {
                        if (key === "images") {
                            for (let i = 0; i < object[key].length; i++) {
                                formData.append(`images[${i}]`, object[key][i])
                            }
                        } else {
                            formData.append(key, object[key])
                        }
                    }
                )
                return formData
            }
            console.log("form data")


            if (requiredFields.every(isNotEmptyInput)) {
                console.log("after validation")

                setLoading(true)
                console.log(request)
                fetch(
                    `${CONFIGS.baseUrl}/api/request/${requestType}`,
                    {
                        method: "POST",
                        redirect: "manual",
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem("kctoken")}`,
                        },
                        body: getFormData(request)
                    }
                )
                    .then((response) => response.ok ? response.json() : Promise.reject(response))
                    .then((result) => {
                        window.sessionStorage.removeItem(location.pathname)
                        setRequestStatus(result)
                        setLoading(false)
                    })
                    .catch((error) => {
                        if (isExists(error.status) && (error.status === 0 || error.status === 401)) {
                            window.location.reload()
                        } else {
                            const jsonError = JSON.stringify(error, null, 4)
                            const jsonRequest = JSON.stringify(request, null, 4)
                            Sentry.captureMessage(
                                `Ошибка при отправке заявки\n\n${jsonError}\n\n${jsonRequest}`,
                                Sentry.Severity.Error
                            )
                            setRequestStatus(error)
                            setLoading(false)
                        }
                    })
            } else {
                setErrorForm(true)
            }
        }

        useEffect(() => {
            if (Object.getOwnPropertyNames(request).length !== 0) {
                window.sessionStorage.setItem(location.pathname, JSON.stringify(request))
            }
        }, [request, location.pathname])

        const getPFCustomFieldValue = (name) => {
            let value = ""
            userProfile.custom_fields.forEach(cf => {
                if (cf.name === name) {
                    value = cf.value
                }
            })
            return value
        }

        useEffect(() => {
            const getHRName = (result) => {
                const hrCF = findValueInArrayCF(result.custom_fields, HR_PF_UUID)
                if (isExists(hrCF?.value)) {
                    setHrName(hrCF.value)
                }
            }

            const isValidDateProfile = (profile) => {
                let brokenData = []
                if (
                    !isExists(profile.position)
                    || !isExists(profile.position.name)
                ) {
                    brokenData = ["position", ...brokenData]
                }
                if (!isExists(profile.mobile_number)) {
                    brokenData = ["mobile number", ...brokenData]
                }
                if (!isExists(profile.email)) {
                    brokenData = ["email", ...brokenData]
                }
                if (
                    !isExists(profile.reporting_to)
                    || !isExists(profile.reporting_to.first_name)
                    || !isExists(profile.reporting_to.last_name)
                ) {
                    brokenData = ["manager", ...brokenData]
                }
                if (
                    !isExists(profile.location)
                    || !isExists(profile.location.name)
                ) {
                    brokenData = ["location", ...brokenData]
                }
                if (
                    !isExists(profile.custom_fields)
                    || !findValueInArrayCF(profile.custom_fields, COUNTRY_PF_UUID).value
                ) {
                    brokenData = ["country", ...brokenData]
                }
                if (
                    !isExists(profile.custom_fields)
                    || !findValueInArrayCF(profile.custom_fields, CITY_PF_UUID).value
                ) {
                    brokenData = ["city", ...brokenData]
                }
                if (!isEmptyArray(brokenData)) {
                    setBrokenProfile(brokenData)
                    return false
                }
                return true
            }

            if (isExists(user)) {
                fetch(`${CONFIGS.baseUrl}/api/v1/employees/peopleforce`,
                    {
                        method: "GET",
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem("kctoken")
                            }`,
                        }
                })
                    .then((response) => response.json())
                    .then((result) => {
                        setLoadingUserProfile(false)
                        getHRName(result)
                        if (isValidDateProfile(result)) setUserProfile(result)
                    })
                    .catch(() => {
                        setLoadingUserProfile(false)
                        setUserProfile({})
                    })
            }

        }, [user])

        const findOfficeIfExist = (location) => isExists(location.trim()) && isExists(OFFICE_BY_LOCATION[location.trim()])
                ? OFFICE_BY_LOCATION[location.trim()]
                : ""

        const findPositionIfExist = (position) => isExists(position.trim())
            ? position.trim()
            : ""
        const findCurrencyByLocation = (location) => isExists(location.trim()) && isExists(OFFICE_BY_LOCATION[location.trim()])
            ? CURRENCY_BY_LOCATION[location.trim()]
            : ""

        if (loadingUserProfile) return <Loading />

        if (!isEmptyArray(brokenProfile)) return <BrokenProfilePopup
            nonexistendData={brokenProfile}
            hrName={hrName}
        />

        return (
            <>
                {isExists(userProfile) &&
                    <>
                        <>
                            <Request
                                handleChange={handleChange}
                                handleChangeCustom={handleChangeCustom}
                                handleChangeCustomOne={handleChangeCustomOne}
                                handleChangeCheckBox={handleChangeCheckBox}
                                handleStartDateChange={handleStartDateChange}
                                handleEndDateChange={handleEndDateChange}
                                handleChangeFiles={handleChangeFiles}
                                handleChangeDate={handleChangeDate}
                                handleDeleteImage={handleDeleteImage}
                                handleSubmission={handleSubmissionIfFormComplete}
                                setRequest={setRequest}
                                setInitRequest={setInitRequest}
                                setRequiredFields={setRequiredFields}
                                errorForm={errorForm}
                                isEmptyInput={isEmptyInput}
                                location={location}
                                userProfile={userProfile}
                                findOfficeIfExist={findOfficeIfExist}
                                findPositionIfExist={findPositionIfExist}

                                findCurrencyByLocation={findCurrencyByLocation}
                                getPFCustomFieldValue={getPFCustomFieldValue}
                                request={request}
                            >
                                <Divider
                                    variant="middle"
                                    sx={{ my: 3 }}
                                />
                                <RequestButtonsStyle>
                                    <Box
                                        className="request__buttons_container"
                                        sx={{ px: "5%" }}
                                    >
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            sx={{ mb: 3 }}
                                            className="request__back_gradient"
                                            onClick={back}
                                        >
                                            Назад
                                        </Button>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            sx={{ mb: 3, mx: 1 }}
                                            className="request__clear_gradient"
                                            onClick={clearRequest}
                                        >
                                            Очистить форму
                                        </Button>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            sx={{ mb: 3 }}
                                            className="request__send_gradient"
                                            onClick={handleSubmissionIfFormComplete}
                                        >
                                            {(requestType !== REVISION_SALARY_REQUEST &&
                                                requestType !== WORKPLACE_COST_REQUEST)?
                                                "Отправить заявку" :
                                                "Расчитать"}
                                        </Button>
                                    </Box>
                                </RequestButtonsStyle>
                            </Request>
                        </>
                        {requestStatus && <RequestResponseStatus
                            request={request}
                            response={requestStatus}
                            setRequestStatus={setRequestStatus}
                            requestType = {requestType}
                        />}
                        {loading && <Loading />}
                    </>}
            </>
        )
    }
}

export default manageRequest
