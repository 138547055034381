import {Navigate, Route, Routes} from "react-router-dom"
import ConferenceCompensationRequest from "../request_components/ConferenceCompensationRequest"
import DeviceRequest from "../request_components/DeviceRequest"
import EducationCompensationRequest from "../request_components/EducationCompensationRequest"
import EmployeeRecommendationRequest from "../request_components/EmployeeRecommendationRequest"
import EnglishLanguageCompensationRequest from "../request_components/EnglishLanguageCompensationRequest"
import RelocationRequest from "../request_components/RelocationRequest"
import SportCompensationRequest from "../request_components/SportCompensationRequest"
import BtripRequest from "../request_components/BtripRequest";
import {DEVICE_REQUEST} from "../requestTypes"
import SelectRequestForm from "../SelectRequestForm"
import BelkaBugsRequest from "../request_components/BelkaBugsRequest";
import ChildrenBirthRequest from "../request_components/ChildrenBirthRequest";
import WeddingRequest from "../request_components/WeddingRequest";
import BookRequest from "../request_components/BookRequest";
import OfficeAppliancesRequest from "../request_components/OfficeAppliancesRequest";
import PurchasesRequest from "../request_components/PurchasesRequest";
import SalaryRevisionRequest from "../request_components/SalaryRevisionRequest";
import PersonalExpensesRequest from "../request_components/PersonalExpensesRequest";
import WorkplaceCostRequest from "../request_components/WorkplaceCostRequest";
import OooRequest from "../request_components/OooRequest";

function AuthenticatedRouting() {
    return (
        <Routes>
            <Route exact path="/" element={<Navigate to="/request"/>}/>
            <Route path="/request" element={<SelectRequestForm/>}/>
            <Route exact path="/request/conference" element={<ConferenceCompensationRequest/>}/>
            <Route exact path={`/request/${DEVICE_REQUEST}`} element={<DeviceRequest/>}/>
            <Route exact path="/request/education" element={<EducationCompensationRequest/>}/>
            <Route exact path="/request/english" element={<EnglishLanguageCompensationRequest/>}/>
            <Route exact path="/request/recommendation" element={<EmployeeRecommendationRequest/>}/>
            <Route exact path="/request/relocation" element={<RelocationRequest/>}/>
            <Route exact path="/request/sport" element={<SportCompensationRequest/>}/>
            <Route exact path="/request/btrip" element={<BtripRequest/>}/>
            <Route exact path="/request/children" element={<ChildrenBirthRequest/>}/>
            <Route exact path="/request/wedding" element={<WeddingRequest/>}/>
            <Route exact path="/request/bugs" element={<BelkaBugsRequest/>}/>
            <Route exact path="/request/book" element={<BookRequest/>}/>
            <Route exact path="/request/appliances" element={<OfficeAppliancesRequest/>}/>
            <Route exact path="/request/purchases" element={<PurchasesRequest/>}/>
            <Route exact path="/request/ooo" element={<OooRequest/>}/>
{/*
            <Route exact path="/request/salary" element={<SalaryRevisionRequest/>}/>
*/}
            <Route exact path="/request/personal" element={<PersonalExpensesRequest/>}/>
            <Route exact path="/request/workplace" element={<WorkplaceCostRequest/>}/>


        </Routes>
    )
}

export default AuthenticatedRouting
