export const GOOGLE_AUTH_URL = `/oauth2/authorize/google`
export const COUNTRY_PF_UUID = 'страна'
export const CITY_PF_UUID = 'город'
export const HR_PF_UUID = 'hr_твоей_команды'
export const HIRE_TYPE = 'вид_оформления'
export const BELKA_LEADS = 'belkaleads'

function getKeycloackConfig() {

    let cfg = {}
    switch (document.location.host) {
        case "localhost:3000": {
            cfg = {
                "realm": "master",
                "url": "http://localhost:8080",
                "ssl-required": "none",
                "resource": "halpdesk-dev",
                "public-client": true,
                "clientId": "halpdesk-dev"
            }
            break;
        }
        case "halpdesk-dev.belka-games.com": {
            cfg = {
                "realm": "master",
                "url": "https://auth.ita.belka-games.com/",
                "ssl-required": "none",
                "resource": "halpdesk-dev",
                "public-client": true,
                "clientId": "halpdesk-dev"
            }
            break;
        }
        default: {
            cfg = {
                "realm": "master",
                "url": "https://auth.ita.belka-games.com/",
                "ssl-required": "none",
                "resource": "halpdesk",
                "public-client": true,
                "clientId": "halpdesk"
            }
        }
    }
    return cfg;
}

function getBaseUrl() {
    switch (document.location.host) {
        case "localhost:3000": {
            return "http://localhost:8095"
        }
        case "halpdesk-dev.belka-games.com": {
            return "https://halpdesk-dev.belka-games.com"

        }
        default:
            return "https://my.belka-games.com"
    }
}

function getEnvironment() {
    switch (document.location.host) {
        case "localhost:3000": {
            return "local"
        }
        case "https://halpdesk-dev.belka-games.com": {
            return "development"
        }
        default:
            return "production"
    }
}

export const CONFIGS = {
    keycloack: getKeycloackConfig(),
    baseUrl: getBaseUrl(),
    environment: getEnvironment()
}