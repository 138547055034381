import {Box, Card, MenuItem, TextField, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import {chooseInitRequest, isExists} from "../../utils"
import {BOOK_REQUEST} from "../requestTypes"
import manageRequest, {SelectProps} from "./hoc/manageRequest"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink";

const REQUIRED_FIELDS = ["employeeFullName", "location", "bookName", "bookAuthor", "language"]

const BookRequest = (props) => {
    const [requestUnavailable, setRequestUnavailable] = useState(false)

    useEffect(() => {
        const initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            location: props.findOfficeIfExist(props.userProfile.location.name.trim()),
            bookName: "",
            bookAuthor: "",
            language: "",
            comment: ""
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)

        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)
        setRequestUnavailable(
            isExists(props.findOfficeIfExist(props.userProfile.location.name.trim()))
                ? !(props.findOfficeIfExist(props.userProfile.location.name) === "Кипр" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Литва" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Грузия" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Украина" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "РБ" ||
                    props.findOfficeIfExist(props.userProfile.location.name) === "Черногория")
                : false
        )

    }, [])

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> заказать книгу</>}/>
            <Box className="select_request">
                {!requestUnavailable ?
                <Card>
                    <Box component="form">
                        <Box sx={{px: "5%"}}>
                            <RequestDescription>
                                Привет! Мы в Belka Games всеми «лапками» выступаем за профессиональное развитие,
                                а чтение книг - хороший способ прокачать свои знания. Если ты хочешь заказать профильную
                                книгу - заполни заявку ниже, и мы ее обязательно рассмотрим :) Ознакомиться с правилами
                                оформления можно
                                <RequestDescriptionLink page="BC/pages/749633682/Belka+Games" spaceBefore>
                                    здесь.
                                </RequestDescriptionLink>
                            </RequestDescription>
                            <TextField
                                required
                                fullWidth
                                sx={{mb: 3, background: "white"}}
                                label="Название книги"
                                name="bookName"
                                value={props.request.bookName ? props.request.bookName : ""}
                                helperText={(props.errorForm && props.isEmptyInput("bookName"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("bookName") : false}
                                onChange={props.handleChange}
                            />
                            <TextField
                                required
                                fullWidth
                                sx={{mb: 3, background: "white"}}
                                label="Автор книги"
                                name="bookAuthor"
                                value={props.request.bookAuthor ? props.request.bookAuthor : ""}
                                helperText={(props.errorForm && props.isEmptyInput("bookAuthor"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("bookAuthor") : false}
                                onChange={props.handleChange}
                            />
                            <TextField
                                style={{
                                    textAlign: "left"
                                }}
                                select
                                fullWidth
                                sx={{mb: 3}}
                                label="Укажи на каком языке нужна книга"
                                SelectProps={SelectProps}
                                className="request__input"
                                name="language"
                                value={props.request.language ? props.request.language : ""}
                                helperText={(props.errorForm && props.isEmptyInput("language"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("language") : false}
                                onChange={props.handleChange}
                            >
                                <MenuItem value="ENG">
                                    Английский
                                </MenuItem>
                                <MenuItem value="RU">
                                    Русский
                                </MenuItem>
                            </TextField>

                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                sx={{mb: 3, background: "white"}}
                                label="Комментарий"
                                name="comment"
                                value={props.request.comment ? props.request.comment : ""}
                                onChange={props.handleChange}
                            />
                        </Box>
                        {props.children}
                    </Box>
                </Card> :
                <Card sx={{m: 4}}>
                    <Typography variant={"h6"} sx={{m: 4}}>
                        Привет! К сожалению, в твоей локации нет офиса Belka Games, поэтому заявку на заказ книги оставить невозможно.
                        <br/>
                        Ты всегда можешь воспользоваться <a href="https://belkagames.miflib.ru">онлайн-библиотекой.</a><br/>
                    </Typography>
                </Card>
                }
            </Box>
        </>
    )
}

export default manageRequest(BookRequest, BOOK_REQUEST)
