import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Typography
} from "@mui/material"
import {useNavigate} from "react-router-dom"
import SalaryRevisionResponse from "./SalaryRevisionResponse";
import CommonResponse from "./CommonResponse";
import {RECOMMENDATION_REQUEST, REVISION_SALARY_REQUEST, WORKPLACE_COST_REQUEST} from "../requestTypes";
import {useState} from "react";
import WorkplaceResponse from "./WorkplaceResponse";

const RequestResponseStatus = ({request, response, setRequestStatus, requestType}) => {
    const navigate = useNavigate()
    const isRecommendation = requestType === RECOMMENDATION_REQUEST;
    const isSalaryRevision = requestType === REVISION_SALARY_REQUEST;
    const isCommonResponse = ((requestType !== REVISION_SALARY_REQUEST) && (requestType !== WORKPLACE_COST_REQUEST));
    const isWorkplaceResponse = requestType === WORKPLACE_COST_REQUEST;

    const [dialogOpen, setDialogOpen] = useState(true)

    const getErrorMessage = (status) => {
        switch (status) {
            case 413:
                return "Превышен лимит на размер файлов"
            case 415:
                return "Заявка содержит неподдерживаемые файлы"
            case 417:
                return "Заявка принята, но во время создания задачи в Asana возникли проблемы. " +
                    "Мы попытаемся создать задачу еще раз, через некоторое время, не беспокойтесь данные не потеряются"
            default:
                return "Что-то пошло не так :("
        }
    }

    const handleOkClickButton = () => {
        if (isSalaryRevision) {
            setDialogOpen(false)
            setRequestStatus(null)
            navigate("/request/salary")
        } else if (isWorkplaceResponse){
            setDialogOpen(false)
            setRequestStatus(null)
            navigate("/request/workplace")
        } else {
            setDialogOpen(false)
            setRequestStatus(null)
            navigate("/request")
        }
    }

    return (
        <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                className={response.status === "ok" ? "request__send_gradient" : "request__clear_gradient"}
                sx={{textAlign: "center", color: "white"}}
            >
                {(isSalaryRevision || isWorkplaceResponse) ? "Результат расчета" : "Статус заявки"}
            </DialogTitle>
            <DialogContent>
                {response.status === "ok" ?
                    <Box>
                        {isCommonResponse &&
                            <CommonResponse response={response}
                                            isRecommendation={isRecommendation}/>
                        }

                        {isSalaryRevision &&
                            <SalaryRevisionResponse result={response.result}/>}
                        {isWorkplaceResponse &&
                            <WorkplaceResponse result={response.result}
                            request = {request}/>}
                    </Box>
                    :
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{textAlign: "center", pt: 3}}
                    >
                        {getErrorMessage(response.status)}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions sx={{justifyContent: "center"}}>
                {response.status === "ok" ? (
                        <Button
                            onClick={handleOkClickButton}
                            className="request__back_gradient"
                            sx={{color: "white"}}
                        >
                            OK
                        </Button>
                    ) :
                    (
                        <Button
                            onClick={() => setRequestStatus(null)}
                            className="request__back_gradient"
                            sx={{color: "white"}}
                        >
                            OK
                        </Button>
                    )}
            </DialogActions>
        </Dialog>
    )
}

export default RequestResponseStatus
