import {MobileDatePicker, DesktopDatePicker, Alert} from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import {
    Box,
    Button,
    Card, Checkbox,
    Chip,
    Container,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem, OutlinedInput, Select,
    Stack,
    TextField,
    FormControlLabel, FormGroup
} from "@mui/material"
import { format, isValid } from "date-fns"
import React, { useEffect } from "react"
import {chooseInitRequest, getHireType, getOrDefault, isExists} from "../../utils"
import {BTRIP_REQUEST, RELOCATION_REQUEST} from "../requestTypes"
import manageRequest, {MenuProps, SelectProps} from "./hoc/manageRequest"
import RequestInputStyle from "./MaterialStyles"
import RequestBar from "./RequestBar"
import RequestDescription from "./RequestDescription"
import RequestDescriptionLink from "./RequestDescriptionLink"

const REQUIRED_FIELDS = [
    "type",
    "passport",
    "birthDate",

    "from_country",
    "from_city",
    "from_date",
    //"from_interval",

    "to_country",
    "to_city",

    "finish_country",
    "finish_city",
    "finish_date",
    //"finish_interval",

    "confirm",
    "images"
]

const BtripRequest = (props) => {
    let hireType = getHireType(props.userProfile.custom_fields);
    let isInStaff = hireType === "Штатный сотрудник";
    useEffect(() => {
        hireType = getHireType(props.userProfile.custom_fields);
        isInStaff = hireType === "Штатный сотрудник";
        const initRequest = {
            hireType: hireType,
            fullName: "",
            type:"",
            citizen:"",
            passport:"",
            birthDate: props.userProfile.date_of_birth ? props.userProfile.date_of_birth : "",
            personalPhone: "",
            from_country:"",
            from_city:"",
            from_address:"",
            from_date:"",
            from_interval:"",

            to_country:"",
            to_city:"",

            finish_country:"",
            finish_city:"",
            finish_date:"",
            finish_interval:"",

            luggage:"",
            purpose:"",
            address:"",
            visa:"",
            visa_info:"",
            vaccines:"",
            validityPeriod:"",

            office: props.userProfile.location.name.trim(),
            phone: props.userProfile.mobile_number,

            images: [],
            comment: "",

            confirm:false
        }

        props.setInitRequest(() => () => props.setRequest(initRequest))

        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));

        props.setRequiredFields(REQUIRED_FIELDS)
    }, [])

    const handleChangeEndDate = (date) => {
        if (isValid(date)) {
            props.setRequest({
                ...props.request,
                from_date: format(date, "yyyy-MM-dd"),
                finish_date: format(date, "yyyy-MM-dd"),
                birthDate: format(date, "yyyy-MM-dd")
            })
        }
    }
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z\s\-]+$/;

    return (
        <>
            {props.request &&
                <>


                    <RequestBar header={<><b>Система заявок:</b> командировка</>} />
                    <Box className="select_request">
                        <Card>
                        <Box component="form">
                            <Box sx={{ px: "5%" }}>
                                <RequestDescription>
                                    Привет!
                                    Через эту форму ты можешь оставить заявку на командировку.
                                    Ознакомиться с правилами оформления можно
                                    <RequestDescriptionLink page="BC/pages/3951886505/Belka+Games" spaceBefore>
                                        здесь
                                    </RequestDescriptionLink>
                                    .
                                </RequestDescription>

                                <TextField
                                    sx={{ mb: 3, mr:"4%", background: "white" }}
                                    fullWidth
                                    required
                                    select
                                    label="Тип командировки"
                                    name="type"
                                    value={props.request.type ? props.request.type : ""}
                                    error={props.errorForm ? props.isEmptyInput("type") : false}
                                    helperText={(props.errorForm && props.isEmptyInput("type")) ? "Поле не должно быть пустым." : ""}
                                    onChange={props.handleChange}
                                >
                                    <MenuItem value={"INDIVIDUAL"}>Индивидуальная</MenuItem>
                                    <MenuItem value={"GROUP"}>Групповая</MenuItem>
                                    <MenuItem value={"TEAM_BUILDING"}>Тимбилдинг</MenuItem>
                                </TextField>



                                <TextField
                                    sx={{ mb: 3, mr:"4%", background: "white" }}
                                    required
                                    fullWidth
                                    label="ФИО"
                                    name="fullName"
                                    onKeyDown={(event) => {
                                        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={getOrDefault(props.request.fullName)}
                                    helperText={(props.errorForm && props.isEmptyInput("fullName"))
                                        ? "Поле не должно быть пустым."
                                        : "*как в паспорте, дающем вам право нахождения за границей"}
                                    error={props.errorForm
                                        ? props.isEmptyInput("fullName")
                                        : false}
                                    onChange={props.handleChange}
                                >

                                </TextField>

                                <TextField
                                    sx={{ mb: 3, background: "white", mr:"4%" }}
                                    required
                                    name="citizen"
                                    label="Гражданство"
                                    fullWidth

                                    value={props.request.citizen}
                                    helperText={(props.errorForm && props.isEmptyInput("citizen"))
                                        ? "Поле не должно быть пустым."
                                        : ""}
                                    error={props.errorForm
                                        ? props.isEmptyInput("citizen")
                                        : false}
                                    onChange={props.handleChange}
                                />



                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputFormat="dd.MM.yyyy"
                                        label="Дата рождения"
                                        name="birthDate"
                                        type="date"

                                        showToolbar={false}
                                        value={props.request.birthDate ? props.request.birthDate : ""}
                                        onChange={(date) => props.handleChangeDate(date, "birthDate")}
                                        renderInput={(params) => <TextField {...params} name="birthDate"
                                                                            required
                                                                            error={props.errorForm ? props.isEmptyInput("birthDate") : false}
                                                                            helperText={(props.errorForm && props.isEmptyInput("birthDate")) ? "Поле не должно быть пустым." : ""}
                                                                            className="request__input"
                                                                            sx={{mr: "3%", mb: 3, width:220}}/>}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    sx={{ mb: 3, background: "white", mr:"4%" }}
                                    required
                                    name="passport"
                                    label="Номер паспорта"
                                    fullWidth
                                    helperText="*как в паспорте, дающем вам право нахождения за границей"

                                    value={props.request.passport}
                                    helperText={(props.errorForm && props.isEmptyInput("passport"))
                                        ? "Поле не должно быть пустым."
                                        : "*как в паспорте, дающем вам право нахождения за границей"}
                                    error={props.errorForm
                                        ? props.isEmptyInput("passport")
                                        : false}
                                    onChange={props.handleChange}
                                />
                                <TextField
                                    sx={{ mb: 3, background: "white", mr:"4%" }}
                                    required
                                    name="personalPhone"
                                    label="Номер телефона"
                                    fullWidth
                                    helperText="*необходимо указать номер телефона, по которому с вами можно будет связаться."

                                    value={props.request.personalPhone}
                                    helperText={(props.errorForm && props.isEmptyInput("personalPhone"))
                                        ? "Поле не должно быть пустым."
                                        : "*необходимо указать номер телефона, по которому с вами можно будет связаться."}
                                    error={props.errorForm
                                        ? props.isEmptyInput("personalPhone")
                                        : false}
                                    onChange={props.handleChange}
                                />
                                <Box sx={{ py: 2 }}>
                                    <Box
                                        sx={{
                                            textAlign: "center",
                                            width: "300px",
                                            display: "block",
                                            margin: "0 auto"
                                        }}
                                    ><label htmlFor="upload-photo">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload-photo"
                                            type="file"
                                            accept="image/*,application/pdf"
                                            multiple
                                            name="images"
                                            onChange={props.handleChangeFiles}
                                        />
                                        <Box>
                                            <Button
                                                fullWidth
                                                component="span"
                                                variant="contained"
                                            >
                                                Загрузить фото паспорта*
                                            </Button>
                                            {(props.errorForm && props.isEmptyInput("images")) &&
                                                <p className="file_loading_helper_text">
                                                    Необходимо загрузить фото/скан
                                                </p>}
                                        </Box>
                                    </label></Box>
                                    <Grid
                                        container
                                        justifyContent="center"
                                    >
                                        <p className="file_loading_helper_text color_black">
                                            <b>Общий размер файлов не должен превышать 30MB</b><br />
                                            *скан паспорта, дающего вам право нахождения за границей, для граждан РБ загружать фото 2-х предпоследних разворотов 31 и 32 стр.)
                                        </p>
                                    </Grid>
                                    {isExists(props.request.images) &&
                                        <Stack
                                            sx={{ textAlign: "center", alignItems: "center", marginTop: 2 }}
                                            direction="column"
                                            spacing={1}
                                        >
                                            {props.request.images.map((image, index) =>
                                                <Chip
                                                    sx={{ width: "50%", justifyContent: "space-between" }}
                                                    key={index}
                                                    label={image.name}
                                                    onDelete={() => props.handleDeleteImage(index)}
                                                />
                                            )}
                                        </Stack>
                                    }
                                </Box>


                                <h2>Откуда выезжаешь</h2>
                                {isInStaff ? <Alert severity="info" sx={{mb: 3, mr: "4%"}}>*согласно <a
                                        href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3951886505#%D0%9E%D0%B1%D1%89%D0%B8%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0-%D0%B4%D0%BB%D1%8F-%D0%BA%D0%BE%D0%BC%D0%B0%D0%BD%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%BE%D0%BA">
                                        правилам оформления командировки</a> сотрудник может быть отправлен в командировку только из страны нахождения офиса его оформления.
                                    </Alert> :
                                    <Alert severity="info" sx={{mb: 3, mr: "4%"}}>*согласно <a
                                        href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3951886505#%D0%9E%D0%B1%D1%89%D0%B8%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0-%D0%B4%D0%BB%D1%8F-%D0%BA%D0%BE%D0%BC%D0%B0%D0%BD%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%BE%D0%BA">
                                        правилам оформления командировки</a> сотрудник может быть официально направлен в
                                        командировку только из страны нахождения. Сотрудник может быть официально
                                        возвращен из командировки только в страну нахождения либо же в локацию последующей
                                        командировки.
                                    </Alert>
                                }
                                <RequestInputStyle>

                                    <TextField
                                        sx={{ mb: 3, background: "white", mr:"4%" }}
                                        required
                                        name="from_country"
                                        label="Страна выезда"
                                        fullWidth

                                        value={props.request.from}
                                        helperText={(props.errorForm && props.isEmptyInput("from_country"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("from_country")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        sx={{ mb: 3, background: "white", mr:"4%" }}
                                        required
                                        fullWidth

                                        label="Город выезда"
                                        name="from_city"
                                        value={props.request.from}
                                        helperText={(props.errorForm && props.isEmptyInput("from_city"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("from_city")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        sx={{ mb: 3, background: "white", mr:"4%" }}
                                        fullWidth
                                        helperText="*адрес прописью или прикрепи ссылку с Google Maps"

                                        label="Укажите адрес для заказа трансфера в локации выезда (откуда забирать и привозить на обратном пути) "
                                        name="from_address"
                                        value={props.request.from_address}
                                        onChange={props.handleChange}
                                    />



                                </RequestInputStyle>



                                <h2>Куда едешь</h2>
                                <TextField
                                    sx={{ mb: 3, mr:"4%", background: "white" }}
                                    required
                                    fullWidth

                                    label="Страна"
                                    name="to_country"
                                    value={props.request.from}
                                    helperText={(props.errorForm && props.isEmptyInput("to_country"))
                                        ? "Поле не должно быть пустым."
                                        : ""}
                                    error={props.errorForm
                                        ? props.isEmptyInput("to_country")
                                        : false}
                                    onChange={props.handleChange}
                                />
                                <TextField
                                    sx={{ mb: 3, mr:"4%", background: "white" }}
                                    required
                                    fullWidth

                                    label="Город"
                                    name="to_city"
                                    value={props.request.from}
                                    helperText={(props.errorForm && props.isEmptyInput("to_city"))
                                        ? "Поле не должно быть пустым."
                                        : ""}
                                    error={props.errorForm
                                        ? props.isEmptyInput("to_city")
                                        : false}
                                    onChange={props.handleChange}
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        inputFormat="dd.MM.yyyy"

                                        label="Дата приезда"
                                        name="from_date"
                                        showToolbar={false}
                                        value={props.request.from_date ? props.request.from_date : ""}
                                        onChange={(date) => props.handleChangeDate(date, "from_date")}
                                        renderInput={(params) => <TextField {...params} name="from_date"
                                                                            required
                                                                            error={props.errorForm ? props.isEmptyInput("from_date") : false}
                                                                            helperText={(props.errorForm && props.isEmptyInput("from_date")) ? "Поле не должно быть пустым." : ""}
                                                                            className="request__input"
                                                                            sx={{mr: "3%", mb: 3,width: 220 }}/>}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    sx={{ mb: 3, mr:"4%", background: "white" }}

                                    fullWidth
                                    select

                                    label="Желаемое время приезда"
                                    name="from_interval"
                                    value={props.request.from}
                                    helperText={(props.errorForm && props.isEmptyInput("from_interval"))
                                        ? "Поле не должно быть пустым."
                                        : ""}
                                    error={props.errorForm
                                        ? props.isEmptyInput("from_interval")
                                        : false}
                                    onChange={props.handleChange}
                                >
                                    <MenuItem value={"0-4"}>0-4 am</MenuItem>
                                    <MenuItem value={"4-8"}>4-8 am</MenuItem>
                                    <MenuItem value={"8-12"}>8-12 am</MenuItem>
                                    <MenuItem value={"12-16"}>12-16 pm</MenuItem>
                                    <MenuItem value={"16-20"}>16-20 pm</MenuItem>
                                    <MenuItem value={"20-24"}>20-24 pm</MenuItem>
                                </TextField>


                                <h2>Куда едешь обратно</h2>
                                {isInStaff ? <Alert severity="info" sx={{mb: 3, mr: "4%"}}>*согласно <a
                                        href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3951886505#%D0%9E%D0%B1%D1%89%D0%B8%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0-%D0%B4%D0%BB%D1%8F-%D0%BA%D0%BE%D0%BC%D0%B0%D0%BD%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%BE%D0%BA">
                                        правилам оформления командировки</a> сотрудник может быть возвращен из
                                        командировки только в страну нахождения офиса его оформления либо же в локацию
                                        последующей командировки.
                                    </Alert> :
                                    <Alert severity="info" sx={{mb: 3, mr: "4%"}}>*согласно <a
                                        href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3951886505#%D0%9E%D0%B1%D1%89%D0%B8%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0-%D0%B4%D0%BB%D1%8F-%D0%BA%D0%BE%D0%BC%D0%B0%D0%BD%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%BE%D0%BA">
                                        правилам оформления командировки</a> сотрудник может быть официально возвращен из
                                        командировки только в страну нахождения либо же в локацию последующей командировки.
                                    </Alert>
                                }
                                <RequestInputStyle>

                                    <TextField
                                        sx={{ mb: 3, background: "white", mr:"4%" }}
                                        required
                                        fullWidth

                                        name="finish_country"
                                        label="Страна приезда"

                                        value={props.request.from}
                                        helperText={(props.errorForm && props.isEmptyInput("finish_country"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("finish_country")
                                            : false}
                                        onChange={props.handleChange}
                                    />
                                    <TextField
                                        sx={{ mb: 3, background: "white", mr:"4%" }}
                                        required
                                        fullWidth

                                        label="Город приезда"
                                        name="finish_city"
                                        value={props.request.from}
                                        helperText={(props.errorForm && props.isEmptyInput("finish_city"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("finish_city")
                                            : false}
                                        onChange={props.handleChange}
                                    />


                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd.MM.yyyy"

                                            label="Дата приезда"
                                            name="finish_date"
                                            showToolbar={false}
                                            value={props.request.finish_date ? props.request.finish_date : ""}
                                            onChange={(date) => props.handleChangeDate(date, "finish_date")}
                                            renderInput={(params) => <TextField {...params} name="finish_date"
                                                                                required
                                                                                error={props.errorForm ? props.isEmptyInput("finish_date") : false}
                                                                                helperText={(props.errorForm && props.isEmptyInput("finish_date")) ? "Поле не должно быть пустым." : ""}
                                                                                className="request__input"
                                                                                sx={{mr: "3%", mb: 3,width: 220 }}/>}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        sx={{ mb: 3, mr:"4%", background: "white" }}

                                        fullWidth
                                        select

                                        label="Желаемое время приезда"
                                        name="finish_interval"
                                        value={props.request.from}
                                        helperText={(props.errorForm && props.isEmptyInput("finish_interval"))
                                            ? "Поле не должно быть пустым."
                                            : ""}
                                        error={props.errorForm
                                            ? props.isEmptyInput("finish_interval")
                                            : false}
                                        onChange={props.handleChange}
                                    >
                                        <MenuItem value={"0-4"}>0-4 am</MenuItem>
                                        <MenuItem value={"4-8"}>4-8 am</MenuItem>
                                        <MenuItem value={"8-12"}>8-12 am</MenuItem>
                                        <MenuItem value={"12-16"}>12-16 pm</MenuItem>
                                        <MenuItem value={"16-20"}>16-20 pm</MenuItem>
                                        <MenuItem value={"20-24"}>20-24 pm</MenuItem>
                                    </TextField>
                                </RequestInputStyle>

                                <h2>Общая информация</h2>
                                <TextField
                                    required
                                    select
                                    SelectProps={SelectProps}
                                    label="Есть ли багаж"
                                    name="luggage"
                                    value={props.request.luggage ? props.request.luggage : ""}
                                    onChange={props.handleChange}
                                    fullWidth
                                    sx={{mb: 3, background: "white"}}
                                    error={props.errorForm ? props.isEmptyInput("luggage") : false}
                                    helperText={(props.errorForm && props.isEmptyInput("luggage")) ? "Поле не должно быть пустым." : ""}
                                >
                                    <MenuItem value={"Да"}>Да</MenuItem>
                                    <MenuItem value={"Нет"}>Нет</MenuItem>
                                </TextField>

                                <TextField
                                    sx={{ mb: 3, background: "white", mr:"4%" }}
                                    required
                                    fullWidth

                                    label="Цель поездки"
                                    name="purpose"
                                    value={props.request.from}
                                    helperText={(props.errorForm && props.isEmptyInput("purpose"))
                                        ? "Поле не должно быть пустым."
                                        : " *развернутое описание"}
                                    error={props.errorForm
                                        ? props.isEmptyInput("purpose")
                                        : false}
                                    onChange={props.handleChange}
                                />

                               {/* <TextField
                                    sx={{ mb: 3, background: "white", mr:"4%" }}
                                    required
                                    fullWidth

                                    label="Адрес проведения мероприятия"
                                    name="address"
                                    value={props.request.from}
                                    helperText={(props.errorForm && props.isEmptyInput("address"))
                                        ? "Поле не должно быть пустым."
                                        : "*необходимо для подбора гостиницы"}
                                    error={props.errorForm
                                        ? props.isEmptyInput("address")
                                        : false}
                                    onChange={props.handleChange}
                                />*/}

                                <TextField
                                    required
                                    select
                                    SelectProps={SelectProps}
                                    label="Есть ли виза"
                                    name="visa"
                                    value={props.request.visa ? props.request.visa : ""}
                                    onChange={props.handleChange}
                                    fullWidth
                                    sx={{mb: 3, background: "white"}}
                                    error={props.errorForm ? props.isEmptyInput("visa") : false}
                                    helperText={(props.errorForm && props.isEmptyInput("visa")) ? "Поле не должно быть пустым." : ""}
                                >
                                    <MenuItem value={"Да"}>Да</MenuItem>
                                    <MenuItem value={"Нет"}>Нет</MenuItem>
                                </TextField>

                                <TextField
                                    sx={{ mb: 3, background: "white", mr:"4%" }}
                                    fullWidth

                                    label="Тип визы и срок действия (при наличии)"
                                    name="visa_info"
                                    value={props.request.visa_info ? props.request.visa_info : ""}
                                    helperText={(props.errorForm && props.isEmptyInput("visa_info"))
                                        ? "Поле не должно быть пустым."
                                        : ""}
                                    error={props.errorForm
                                        ? props.isEmptyInput("visa_info")
                                        : false}
                                    onChange={props.handleChange}
                                />


                                <TextField
                                    sx={{ mb: 3, background: "white" }}
                                    fullWidth
                                    multiline
                                    label="Комментарий"
                                    name="comment"
                                    value={getOrDefault(props.request.comment)}
                                    onChange={props.handleChange}
                                />

                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox error={props.errorForm
                                            ? props.request.confirm==true
                                            : false} defaultChecked value={props.request.confirm ? props.request.confirm : ""} />} label="Согласен на обработку персональных данных" />
                                </FormGroup>
                            </Box>
                            {props.children}
                        </Box>
                        </Card>
                    </Box>
                </>
            }
        </>
    )
}

export default manageRequest(BtripRequest, BTRIP_REQUEST)
