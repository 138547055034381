import {AppBar, Box, Button, createTheme, styled, ThemeProvider, Toolbar, Typography} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom"
import {isExists} from "../../utils";
import CollapseRightBar from "./CollapseRightBar";
import {keycloak} from "../../keycloak-config";
import {logoutUser} from "../../redux/actions";

export default function RequestBar(props) {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user);
    const themeLogoutButton = createTheme({
        palette: {
            primary: {
                main: "#ffffff",
            }
        },
    });

    const RequestBarStyle = styled('div')(({theme}) => ({
        [theme.breakpoints.down('iphonePRO')]: {
            ".request_bar__right_no_wrapped": {
                display: "none"
            },
            ".request_bar__header_text" : {
                fontSize: "0.9rem",
                maxWidth: "200px"
            },
            ".request_bar__card_container": {
                display: "flex",
                justifyContent: "space-between"
            }
        },
        [theme.breakpoints.down('tablet')]: {
            ".request_bar__right_no_wrapped": {
                display: "none"
            },
            ".request_bar__header_text" : {
                fontSize: "1rem",
            },
        },
        [theme.breakpoints.up('tablet')]: {
            ".request_bar__right_wrapped": {
                display: "none"
            },

        }

    }));

    const handleClickLogo = () => {
        navigate("/request")
    }

    function logout() {
        logoutUser();
        keycloak.logout();
    }

    return (
        <AppBar position="static" sx={{backgroundImage: "linear-gradient(90deg, #652cc1, #4e68de, #58a4f9)"}}>
            <RequestBarStyle>
                <Toolbar className="request_bar__card_container" sx={{display: "grid"}}>
                    <div className="request_bar__form_logo" onClick={handleClickLogo}/>
                    <Typography
                        className="request_bar__header_text"
                        sx={{ textAlign: "center" }}
                        variant="h6"
                        component="div"
                    >
                        {props.header}
                    </Typography>
                    <Box sx={{display: "flex", justifyContent: "end", alignItems: "baseline"}}>
                        <Box className="request_bar__right_no_wrapped" sx={{display: "flex", alignItems: "baseline"}}>
                            <Typography
                                sx={{ textAlign: "right", mr: "20px" }}
                                variant="subtitle1"
                                component="div"
                            >
                                {isExists(user) && user.email}
                            </Typography>
                            <ThemeProvider theme={themeLogoutButton}>
                                <Button variant="outlined" onClick={keycloak.logout}>Logout</Button>
                            </ThemeProvider>
                        </Box>
                        <Box className="request_bar__right_wrapped">
                            <CollapseRightBar logout={logout}/>
                        </Box>
                    </Box>
                </Toolbar>
            </RequestBarStyle>
        </AppBar>
    )
}
