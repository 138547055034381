import { Close } from "@mui/icons-material"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton
} from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import { isDevelopment } from "../../utils"

const WarningDialog = (props) => {
    const navigate = useNavigate()

    const back = () => {
        navigate("/request")
    }

    const Title = () => (
        <DialogTitle>
            <b>Внимание</b>
            {isDevelopment() && (
                <IconButton
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={props.onClose}
                >
                    <Close />
                </IconButton>
            )}
        </DialogTitle>
    )

    return (
        <Dialog open={props.open}>
            <Title />
            <DialogContent>
                <DialogContentText>
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent="center"
                >
                    <Button
                        className="request__back_gradient"
                        variant="contained"
                        onClick={back}
                    >
                        Назад
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default WarningDialog
