import React, {useEffect} from "react";
import RequestBar from "./RequestBar";
import {Box, Button, Card, Chip, Grid, MenuItem, Stack, TextField, Typography} from "@mui/material";
import RequestDescription from "./RequestDescription";
import manageRequest, {SelectProps} from "./hoc/manageRequest";
import {chooseInitRequest, getOrDefault, isExists} from "../../utils";
import {BELKA_BUGS_REQUEST, OOO_REQUEST} from "../requestTypes";
import RequestDescriptionLink from "./RequestDescriptionLink";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {MobileDatePicker} from "@mui/lab";
import moment from "moment";

const REQUIRED_FIELDS = [
    "employeeFullName",
    "description",
    "endOfEvent",
    "startOfEvent",
    "office"
]
const OooRequest = (props) => {
    useEffect(() => {
        const initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            office: props.findOfficeIfExist(props.userProfile.location.name),
            description: "",
            endOfEvent: "",
            startOfEvent: "",
        }

        console.log('initRequest')
        console.log(initRequest)

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)

        props.setRequest(chooseInitRequest(sessionRequest, initRequest));

        props.setRequiredFields(REQUIRED_FIELDS)

    }, [])

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> Работа вне Грузии</>}/>
            <Box className="select_request">
                <Card>
                    <Box component="form">
                        <Box sx={{px: "5%"}}>
                            <RequestDescription>
                                Это заявка только для работников грузинского офиса. В связи с получением статуса международной компании, все работники должны работать на территории Грузии. Поэтому вход в корпоративные системы (Google Space, Asana, Microsoft) будет ограничен в других странах.  Если вы знаете, что будете работать вне Грузии, нужно указать даты, когда вам нужно включить доступы.
                                <br />Доступы будут отключены даже если вы в отпуске, командировке или в выходные дни.
                                <br /><b>Если доступ нужно включить срочно, обратитесь в канал #sys-admins в Slack</b>
                            </RequestDescription>

                            <Typography
                                sx={{mb: 2}}
                                variant="body2">
                                Даты работы вне Грузии
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            showToolbar={false}
                                            label="Дата начала"
                                            name="eventStartDate"
                                            value={getOrDefault(props.request.startOfEvent)}
                                            onChange={props.handleStartDateChange}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    sx={{width: "100%", mb: 3, background: "white"}}
                                                    required
                                                />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            showToolbar={false}
                                            label="Дата оканчания"
                                            name="eventEndDate"
                                            value={getOrDefault(props.request.endOfEvent)}
                                            onChange={props.handleEndDateChange}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    sx={{width: "100%", mb: 3, background: "white"}}
                                                    required
                                                />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                            <TextField
                                required
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 120 }}
                                max
                                rows={2}
                                sx={{ mb: 3, background: "white",   whiteSpace: 'normal', textOverflow: 'ellipsis' }}
                                label="Комментарий"
                                name="description"
                                value={props.request.description ? props.request.description : ""}
                                helperText={(props.errorForm && props.isEmptyInput("description"))
                                    ? "Поле не должно быть пустым."
                                    : ""}
                                error={props.errorForm ? props.isEmptyInput("description") : false}
                                onChange={props.handleChange}
                            />

                        </Box>
                        {props.children}
                    </Box>
                </Card>
            </Box>
        </>
)
}

export default manageRequest(OooRequest, OOO_REQUEST)