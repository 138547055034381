import moment from "moment";
import {BELKA_LEADS, CONFIGS, HIRE_TYPE} from "./constants";

export const isExists = (value) => value !== undefined && value !== null

export const isEmptyValue = (value) => value.trim().length === 0

export const isEmptyArray = (value) => value.length === 0

export const findValueInArrayCF= (array, uuid) => {
    if (!isExists(uuid)) return
    return array?.find(element=> element?.internal_name === uuid)
}

export const isDate = (value) => isExists(value) && value instanceof Date

export const isValidDate = (value) => isDate(value) && !isNaN(value.getTime())

export const getOrDefault = (value, defaultValue = "") => isExists(value) ? value : defaultValue

export const isDevelopment = () =>
    (isExists(process.env.NODE_ENV) && process.env.NODE_ENV === "development")
    || (isExists(process.env.REACT_APP_PROFILE) && process.env.REACT_APP_PROFILE === "development")

export const isLocal = () => window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"

export const getMonthDifference = (
    startDate,
    endDate
) => endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear())

export const chooseInitRequest = (sessionRequest, initRequest) => {
    if (isExists(sessionRequest)) {
        let sessionRequestParsed = JSON.parse(sessionRequest);
        Object.keys(initRequest).map(key => {
            if(!sessionRequestParsed.hasOwnProperty(key)) {
                sessionRequestParsed[key] = initRequest[key]
            }
        })
        sessionRequestParsed.images = [];
        return sessionRequestParsed;
    } else {
        return initRequest;
    }
}

export const getDiffDateByMonth = (startDate, endDate) => {
   return  moment(startDate).diff(moment(endDate), 'months', true)
}

export const getHireType = (custom_fields) => {
    const hireType = findValueInArrayCF(custom_fields, HIRE_TYPE)
    if (isExists(hireType?.value)) {
        return hireType.value;
    }
    return "ИП";
}

export const userIsLead = (userProfile) => {
    if (isExists(userProfile.custom_fields)) {
        const isLead = findValueInArrayCF(userProfile.custom_fields, BELKA_LEADS)
        /*if (userProfile.email === "artsiom_lyhach@belkatechnologies.com" ||
            userProfile.email === "natalia_shchiptsova@belkatechnologies.com") {
            return true;*/
        if (isExists(isLead?.value)) {
            return isLead.value === "Да";
        }
    }
    return false;
}

export const isUserAllowedForWorkplace = (userProfile) => {
    console.log(userProfile)
    if (isExists(userProfile.department) && isExists(userProfile.department.name)) {
        //todo убрать полсе тестирования
        if (userProfile.email === "natalia_shchiptsova@belkatechnologies.com") {
            return true;
        } else if (userProfile.department.name.toLowerCase().includes("hr") ||
            userProfile.department.name.toLowerCase().includes("recruitment") ||
            userProfile.department.name.toLowerCase().includes("finance")){
            return true;
        }
    }
    return false;
}