import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import * as Sentry from "@sentry/react";

function BrokenProfilePopup({nonexistendData, hrName}) {
    const navigate = useNavigate();

    useEffect(() => {
        Sentry.captureMessage(
            `Ошибка при подгрузки данных из PF\n\n${nonexistendData.join(",")}`)
    },[])

    return <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title" className="request__clear_gradient"
                     sx={{textAlign: "center", color: "white"}}>Статус профиля</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign: "center", pt: 3}}>
                У тебя не до конца заполнены данные в PF:<br/>
                '{nonexistendData.join(", ")}'<br/>
                Обратись пожалуйста к своему HR {hrName}
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center"}}>
            <Button onClick={() => navigate("/request")} className="request__back_gradient"
                    sx={{color: "white"}}>OK</Button>
        </DialogActions>
    </Dialog>

}

export default BrokenProfilePopup;
