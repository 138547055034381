import { Link } from "@mui/material"
import React from "react"

const RequestDescriptionLink = (props) => (
    <>
        {props.spaceBefore && " "}
        <Link
            href={`https://belka-games.atlassian.net/wiki/spaces/${props.page}`}
            rel="noopener noreferrer"
            target="_blank"
        >
            {props.children}
        </Link>
        {props.spaceAfter && " "}
    </>
)

export default RequestDescriptionLink
