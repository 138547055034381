import React, {useEffect, useMemo, useState} from "react";
import {chooseInitRequest, getOrDefault, isExists} from "../../utils";
import RequestBar from "./RequestBar";
import {Box, Button, Card, Chip, CircularProgress, Grid, MenuItem, Stack, TextField} from "@mui/material";
import RequestDescription from "./RequestDescription";
import manageRequest, {SelectProps} from "./hoc/manageRequest";
import {PURCHASES_REQUEST} from "../requestTypes";
import RequestDescriptionLink from "./RequestDescriptionLink";
import {CONFIGS} from "../../constants";
import {Autocomplete} from "@mui/lab";

const REQUIRED_FIELDS = [
    "isNewPartner",
    "partnerName",
    "belkaLegalEntity",
    "purcheses",
]

const PurchasesRequest = (props) => {

    const isNewPartner = () => {
        return props.request.isNewPartner === "Да"
    }
    const getLocationPartners = (partnersList, location, isNewPartner) => {
        const newPartner = isNewPartner === "Да"

        if (partnersList && partnersList.length !== 0) {
            if (location === '' || location === undefined || newPartner) {
                return partnersList
            }
            return partnersList.filter(item => item.location === location)
        }
        return [];
    }

    const [isPartnersLoaded, setIsPartnersLoaded] = useState(false);
    const [partners, setPartners] = useState([]);
    const filteredPartners = useMemo(() => getLocationPartners(partners, props.request.belkaLegalEntity, props.request.isNewPartner),
        [props.request.belkaLegalEntity, props.request.isNewPartner, partners]);
    /*const selectedPartner = useMemo(() => getSelectedPartner(props.request.belkaLegalEntity, props.request.isNewPartner),
        [props.request.belkaLegalEntity, props.request.isNewPartner]);*/
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPartner, setSelectedPartner] = useState({name:"", asanaTaskGid: ""});


    useEffect(() => {
        const initRequest = {
            employeeFullName: props.userProfile.normalized_full_name ? props.userProfile.normalized_full_name : "",
            location: props.findOfficeIfExist(props.userProfile.location.name.trim()),
            isNewPartner: "Да",
            partnerName: "",
            belkaLegalEntity: "",
            purcheses: "",
            comment: "",
            partnerTaskGid: "",
            images: []

        }

        props.setInitRequest(() => () => props.setRequest(initRequest))
        const sessionRequest = window.sessionStorage.getItem(props.location.pathname)
        props.setRequest(chooseInitRequest(sessionRequest, initRequest));
        props.setRequiredFields(REQUIRED_FIELDS)
    }, [])

    useEffect(() => {
        if (!isPartnersLoaded) {
            fetch(`${CONFIGS.baseUrl}/api/v1/partners`,
                {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${sessionStorage.getItem("kctoken")
                        }`,
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setIsPartnersLoaded(true);
                    setPartners(result)
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsPartnersLoaded(false);
                    setPartners([])
                })
        }
    }, []);

    function getDistinctPartners(partners) {
        let uniqueItems = []
        let uniqueNames = [];
        partners.forEach(item => {
            if (!uniqueNames.includes(item.name)) {
                uniqueNames.push(item.name);
                uniqueItems.push(item);
            }
        })
        return uniqueItems;
    }

    const handleNewPartner = (event, newValue) => {
        if (newValue === undefined) {
            props.handleChangeCustom("partnerName", event.target.value, "partnerTaskGid", "");
            setSelectedPartner({name:event.target.value, asanaTaskGid: ""})
        } else {
            props.handleChangeCustom("partnerName", newValue.name, "partnerTaskGid", newValue.asanaTaskGrid)
            setSelectedPartner(newValue)
        }
    }

    useEffect(() => {
        if (props.request.isNewPartner === "Да") {
            console.log("is new partners no need changes")
        } else {
            props.handleChangeCustom("partnerName", "", "partnerTaskGid", "")
            setSelectedPartner({name:"", asanaTaskGid: ""});
        }

    },[props.request.isNewPartner, props.request.belkaLegalEntity])

    return (
        <>
            <RequestBar header={<><b>Система заявок:</b> Закупки</>}/>
            <Box className="select_request">
                <Card>
                    <Box component="form">
                        <Box sx={{px: "5%"}}>
                            <RequestDescription>
                                Политика по оплатам и работе с контрагентами <a href={"https://docs.google.com/document/d/1al3Dju5k_wg1CObanXsSGUag5qyWKaGfaR8_t9MX_kw/edit"}> здесь</a>
                            </RequestDescription>
                            {isLoading ? <CircularProgress/> :
                            <Box>
                                <TextField
                                    required
                                    select
                                    SelectProps={SelectProps}
                                    label="Юридическое лицо Belka, с которого будет проходить оплата"
                                    name="belkaLegalEntity"
                                    value={props.request.belkaLegalEntity}
                                    onChange={props.handleChange}
                                    fullWidth
                                    sx={{mb: 3, background: "white"}}
                                    error={props.errorForm ? props.isEmptyInput("belkaLegalEntity") : false}
                                    helperText={(props.errorForm && props.isEmptyInput("belkaLegalEntity")) ? "Поле не должно быть пустым." : ""}
                                >
                                    <MenuItem value={"Belarus"}>Belarus</MenuItem>
                                    <MenuItem value={"Ukraine"}>Ukraine</MenuItem>
                                    <MenuItem value={"Cyprus"}>Cyprus</MenuItem>
                                    <MenuItem value={"Lithuania"}>Lithuania</MenuItem>
                                    <MenuItem value={"Poland"}>Poland</MenuItem>
                                    <MenuItem value={"Montenegro"}>Montenegro</MenuItem>
                                    <MenuItem value={"Georgia"}>Georgia</MenuItem>
                                    <MenuItem value={"Samfinaco"}>Samfinaco</MenuItem>
                                    <MenuItem value={"United"}>United</MenuItem>
                                </TextField>
                                <TextField
                                    required
                                    select
                                    SelectProps={SelectProps}
                                    label="Это новый контрагент?"
                                    name="isNewPartner"
                                    value={props.request.isNewPartner ? props.request.isNewPartner : ""}
                                    onChange={props.handleChange}
                                    fullWidth
                                    sx={{mb: 3, background: "white"}}
                                    error={props.errorForm ? props.isEmptyInput("isNewPartner") : false}
                                    helperText={(props.errorForm && props.isEmptyInput("isNewPartner")) ? "Поле не должно быть пустым." : ""}
                                >
                                    <MenuItem value={"Да"}>Да</MenuItem>
                                    <MenuItem value={"Нет"}>Нет</MenuItem>
                                </TextField>
                            {
                                isNewPartner() ?
                                    <Autocomplete
                                        freeSolo
                                        id="combo-box-demo"
                                        name="partnerName"
                                        value={selectedPartner}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}

                                        options={getDistinctPartners(filteredPartners)}
                                        sx={{mb: 2}}
                                        onChange={(event, newValue) => {
                                            handleNewPartner(event, newValue);
                                        }}
                                        getOptionLabel={(option) =>  option.name }
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       variant="outlined"
                                                       color="warning"
                                                       name="parnerName"
                                                       onChange={(event, newValue) => {
                                                           handleNewPartner(event, newValue);
                                                       }}
                                                       label="Название юридического лица контрагента на английском"
                                                       error={props.errorForm ? props.isEmptyInput("partnerName") : false}
                                                       helperText={(props.errorForm && props.isEmptyInput("partnerName")) ? "Поле не должно быть пустым." : "*Пример - Example company LLC (Брендовое имя, как правило, не совпадает с названием юридического лица (Belka в Литве - это UAB Belka Games LT)"}

                                            />}
                                    />
                                    :
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        name="partnerName"
                                        value={selectedPartner}
                                        options={filteredPartners}
                                        sx={{mb: 2}}
                                        onChange={(event, newValue) => {
                                            handleNewPartner(event, newValue);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}

                                        getOptionLabel={(option) =>  option.name }
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       variant="outlined"
                                                       color="warning"
                                                       name="parnerName"
                                                       value={Object.create({ name: props.request.partnerName, asanaTaskGid: props.request.partnerTaskGid})}

                                                       label="Выбери контрагента из списка"
                                                       error={props.errorForm ? props.isEmptyInput("partnerName") : false}
                                                       helperText={(props.errorForm && props.isEmptyInput("partnerName")) ? "Поле не должно быть пустым." : ""}

                                            />}
                                    />

                            }
                            <TextField
                                required
                                multiline
                                label="Какие товары/ работы/ услуги покупаем"
                                name="purcheses"
                                value={props.request.purcheses ? props.request.purcheses : ""}
                                rows={3}
                                fullWidth
                                sx={{mb: 3, background: "white"}}
                                onChange={props.handleChange}
                                error={props.errorForm ? props.isEmptyInput("purcheses") : false}
                                helperText={(props.errorForm && props.isEmptyInput("purcheses")) ? "Поле не должно быть пустым." : ""}
                            />
                            <TextField
                                sx={{mb: 3, background: "white"}}
                                fullWidth
                                multiline
                                label="Комментарий"
                                name="comment"
                                value={getOrDefault(props.request.comment)}
                                onChange={props.handleChange}
                            />
                            {
                                (isNewPartner() === false) &&
                                <Box sx={{py: 2}}>
                                    <Box
                                        sx={{
                                            textAlign: "center",
                                            width: "300px",
                                            display: "block",
                                            margin: "0 auto"
                                        }}
                                    ><label htmlFor="upload-photo">
                                        <input
                                            style={{display: "none"}}
                                            id="upload-photo"
                                            type="file"
                                            accept="image/*,application/pdf"
                                            multiple
                                            name="images"
                                            onChange={props.handleChangeFiles}
                                        />
                                        <Box>
                                            <Button
                                                fullWidth
                                                component="span"
                                                variant="contained"
                                            >
                                                Загрузить счет на оплату
                                            </Button>
                                        </Box>
                                    </label></Box>
                                    <Grid
                                        container
                                        justifyContent="center"
                                    >
                                        <p className="file_loading_helper_text color_black">
                                            <b>Общий размер файлов не должен превышать 30MB</b><br/>
                                        </p>
                                    </Grid>
                                    {isExists(props.request.images) &&
                                        <Stack
                                            sx={{textAlign: "center", alignItems: "center", marginTop: 2}}
                                            direction="column"
                                            spacing={1}
                                        >
                                            {props.request.images.map((image, index) =>
                                                <Chip
                                                    sx={{width: "50%", justifyContent: "space-between"}}
                                                    key={index}
                                                    label={image.name}
                                                    onDelete={() => props.handleDeleteImage(index)}
                                                />
                                            )}
                                        </Stack>
                                    }
                                </Box>
                            }
                        </Box>}
                        </Box>
                        {props.children}
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default manageRequest(PurchasesRequest, PURCHASES_REQUEST)